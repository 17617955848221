import * as React from 'react';
import { IconProps } from './icon.types';
import { colors } from 'app/colors';

export const TrophyIcon: React.FC<IconProps> = ({
  color = colors.whiteColor,
  width = 48,
  height = 48,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M44 12H38V10C38 8.89539 37.1046 8 36 8H12C10.8954 8 10 8.89539 10 10V12H4C2.89539 12 2 12.8954 2 14V18C2 21.3137 4.68628 24 8 24H10.3335C11.6576 30.0466 16.8868 34.6241 23.25 34.9622V40.5H16.762C16.3478 40.5 16.012 40.8358 16.012 41.25C16.012 41.6642 16.3478 42 16.762 42H31.262C31.6761 42 32.012 41.6642 32.012 41.25C32.012 40.8358 31.6761 40.5 31.262 40.5H24.75V34.9622C31.1132 34.6243 36.3424 30.0466 37.6665 24H40C43.3137 24 46 21.3137 46 18V14C46 12.8954 45.1046 12 44 12ZM10 22.5H8C5.51868 22.5 3.5 20.4813 3.5 18V14C3.5 13.7242 3.72424 13.5 4 13.5H10V22.5ZM36.5 21C36.5 27.8926 30.8926 33.5 24 33.5C17.1074 33.5 11.5 27.8926 11.5 21V10C11.5 9.72424 11.7242 9.5 12 9.5H36C36.2758 9.5 36.5 9.72424 36.5 10V21ZM44.5 18C44.5 20.4813 42.4813 22.5 40 22.5H38V13.5H44C44.2758 13.5 44.5 13.7242 44.5 14V18Z'
      fill={color}
    />
  </svg>
);
