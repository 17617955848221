import { FC } from 'react';
import { Logo } from 'shared/logo';
import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { Text } from 'shared/typography';
import { Gradient } from 'shared/gradient';
import { ButtonWrapper, CentredWrapper } from 'shared/layout';
import { LogoContainer, TextContainer } from './SophiaWelcoming.styled';

interface ISophiaWelcomingUIProps {
  handleClickBtn: () => void;
}

export const SophiaWelcomingUI: FC<ISophiaWelcomingUIProps> = ({
  handleClickBtn,
}) => {
  return (
    <CentredWrapper>
      <LogoContainer>
        <Logo gradient width={66} height={60} />
      </LogoContainer>
      <TextContainer>
        <Text
          style={{
            textAlign: 'center',
            marginBottom: '12px',
            lineHeight: '24px',
          }}
        >
          Welcome to SINT <br />
        </Text>
        <Text style={{ textAlign: 'center', lineHeight: '24px' }}>
          every tap and choice you make <br />
          shapes the evolution of <br />
          Synthetic intelligence - <br />
          journey begins now! <br />
        </Text>
      </TextContainer>
      <ButtonWrapper
        style={{
          position: 'absolute',
          bottom: '87px',
        }}
      >
        <Button
          onClick={handleClickBtn}
          color={colors.notIntensiveColor}
          borderColor={colors.primaryColor}
          decoration='basic'
          style={{ height: '56px', width: '160px', padding: '0px' }}
        >
          <Gradient color={colors.primaryColor}>
            <Text>Start</Text>
          </Gradient>
        </Button>
      </ButtonWrapper>
    </CentredWrapper>
  );
};
