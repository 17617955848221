import styled, { css, keyframes } from 'styled-components';
import { lightenColor } from 'shared/utils/colorUtils';
import { GradientCircleWrapperProps } from './GradientTimer.types';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.6);
  }

  100% {
    transform: scale(1);
  }
`;

export const GradientCircleWrapper = styled.div<GradientCircleWrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 97%;
  border-radius: 50%;
  box-sizing: border-box;
  ${({ isAnim }) =>
    isAnim
      ? css`
          animation: ${pulse} 1.5s infinite;
        `
      : ''}

  ${({ isBlum, status, color }) => {
    if (status === 'success') {
      return css`
        border: none;
        filter: none;
        box-shadow: none;
      `;
    }

    if (status === 'failed') {
      return css`
        border: 2px solid var(--tertiary-color); /* Ensure proper border */
        filter: none; /* Disable bloom */
        box-shadow: none;
      `;
    }

    // Default styles for other statuses
    return !isBlum
      ? css`
          border: 2px solid ${color};

          box-shadow:
            0 0 15px ${color},
            0 0 17px ${color},
            inset 0 0 12px ${color},
            inset 0 0 6px ${color};
        `
      : css`
          border: none;
          filter: none;
          box-shadow: none;
        `;
  }}
`;

export const StyledSVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CircleContent = styled.div`
  height: 100%;
  font-size: 12px;
  width: 100%;
`;
