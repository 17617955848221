import { ROUTES } from 'app';
import { useState } from 'react';
import { PageContent } from 'shared/layout';
import { Wrapper } from './Welcoming.styled';
import { useNavigate } from 'react-router-dom';
import {
  SophiaWelcomingUI,
  SophiaWelcomingVideo,
} from 'feature/sophiaWelcoming';
import { WelcomeModal } from 'feature/welcome';

export const Welcoming = () => {
  const { showModal } = WelcomeModal();
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);

  const handleClickStart = () => {
    setShowVideo(true);
  };

  const handleClickSkip = () => {
    navigate(ROUTES.MINT, { replace: true });
    showModal();
  };

  return (
    <Wrapper>
      <SophiaWelcomingVideo
        isAutoPlay={true}
        showVideo={showVideo}
        handleClickSkip={handleClickSkip}
        handleVideoEnd={handleClickSkip}
      />
      <PageContent
        style={{
          opacity: !showVideo ? 1 : 0,
          transition: 'opacity 0.35s ease',
        }}
      >
        <SophiaWelcomingUI handleClickBtn={handleClickStart} />
      </PageContent>
    </Wrapper>
  );
};
