import { H100, Header, PageContent, PageWrapper } from 'shared/layout';
import { Navback } from 'feature/navback';
import { PageTitle } from 'shared/typography';
import { ConnectWalletContent } from 'widget/connectWallet';
import { Menu } from 'widget/menu';

export const ConnectWallet = () => {
  return (
    <PageWrapper>
      <Header>
        <Navback />
        <PageTitle>Wallet</PageTitle>
        <div style={{ width: '48px' }}></div>
      </Header>
      <PageContent>
        <ConnectWalletContent />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
