import { Tabs } from 'shared/tabs';
import {
  Balance,
  IconWrapper,
  Name,
  Position,
  StyledButton,
} from './LeaderBoard.styled';
import { colors } from 'app/colors';
import { CoinIcon, LeaderIcon } from 'shared/icon';
import {
  useLeaderboardDailyMutation,
  useLeaderboardMutation,
  useLeaderboardReferralsMutation,
} from 'entities/user/model/api';
import { useEffect } from 'react';
import { Loader } from 'shared/loader';
import { useAppSelector } from 'app/store/rootStore';
import { selectLeaderboard } from 'entities/user';
import { CentredWrapper, H100 } from 'shared/layout';
import { Soon } from 'shared/soon';
import { CircleBg } from 'feature/game/balance';

const getBorderColor = (index: number) => {
  if (index < 1) return colors.secondaryColor;
  if (index < 3) return colors.whiteColor;
  return colors.tertiaryColor;
};

const getBackgroundColor = (index: number) =>
  index < 1 ? '#D8F34F1A' : index < 3 ? colors.listItemBG : 'transperent';

const getBgFilter = (index: number) =>
  index > 0 && index < 3 ? 'blur(10px)' : 'none';

const getBgColor = (index: number) =>
  index < 1 ? colors.secondaryColor : colors.whiteColor;

const getDecoration = (index: number) => (index < 3 ? 'basic' : undefined);

const getPosDecoration = (index: number) => {
  if (index < 1) return colors.secondaryColor;
  if (index < 3) return colors.whiteColor;
  return colors.transperent;
};

const renderTabContent = (
  tab: 'GLOBAL' | 'REFERRALS' | 'DAILY',
  data: any[]
) => (
  <H100>
    {data.map(({ username, amount }, index) => (
      <StyledButton
        key={`${tab}-${index}`}
        borderSize={index < 3 ? 0 : 0.5}
        borderColor={getBorderColor(index)}
        color={getBackgroundColor(index)}
        decoration={getDecoration(index)}
        style={{
          color: getBgColor(index),
          backdropFilter: getBgFilter(index),
        }}
      >
        <Position>
          {' '}
          <LeaderIcon value={index + 1} color={getPosDecoration(index)} />
        </Position>
        <Name>{username}</Name>
        <Balance>
          {amount}
          {tab !== 'REFERRALS' && (
            <IconWrapper>
              <CentredWrapper>
                {' '}
                <CircleBg>
                  <CoinIcon />
                </CircleBg>
              </CentredWrapper>
            </IconWrapper>
          )}
        </Balance>
      </StyledButton>
    ))}
  </H100>
);

export const LeaderBoardTabs = () => {
  const [getLeaderboard, { isLoading: loading }] = useLeaderboardMutation();
  const [getLeaderboardDaily, { isLoading: isDailyLoading }] =
    useLeaderboardDailyMutation();
  const [getLeaderboardReferrals, { isLoading: isReferralsLoading }] =
    useLeaderboardReferralsMutation();

  useEffect(() => {
    getLeaderboard('');
    getLeaderboardDaily('');
    getLeaderboardReferrals('');
  }, []);

  const { top, daily, referrals } = useAppSelector(selectLeaderboard);

  const isData = top.length > 0;

  const tabItems = [
    {
      title: 'DAILY',
      segment:
        !isDailyLoading || isData ? (
          renderTabContent('DAILY', daily)
        ) : (
          <H100>
            <Loader />
          </H100>
        ),
      disable: false,
    },
    {
      title: 'GLOBAL',
      segment:
        !loading || isData ? (
          renderTabContent('GLOBAL', top)
        ) : (
          <H100>
            {' '}
            <Loader />
          </H100>
        ),
      disable: false,
    },
    {
      title: 'REFERRALS',
      segment:
        !isReferralsLoading || isData ? (
          <H100>
            <Soon
              title='Coming soon...'
              descr='Referral rating will be added in the nearest future'
            />
          </H100>
        ) : (
          <H100>
            {' '}
            <Loader />
          </H100>
        ),

      disable: false,
    },
  ];

  return <Tabs defaultActiveTab='GLOBAL' items={tabItems} />;
};
