import { QRScreen } from 'pages/QRScreen';
import { ReactNode } from 'react';
import { BrowserView, MobileOnlyView, TabletView } from 'react-device-detect';

export const DeviceProvoider = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <MobileOnlyView>{children}</MobileOnlyView>
      <BrowserView>
        <QRScreen />
      </BrowserView>
      <TabletView>
        <QRScreen />
      </TabletView>
    </>
  );
};
