import { NavLink, useLocation, useRoutes } from 'react-router-dom';
import { Item, Wrapper, StyledIcon } from './Menu.styled';
import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';
import {
  GameIcon,
  Logo,
  ProfileIcon,
  RaitingIcon,
  TasksIcon,
} from 'shared/icon';
import { ROUTES } from 'app';
import { useAppSelector } from 'app/store/rootStore';
import { selectIsMinted } from 'entities/user';
import {
  selectMenuVisability,
  selectorIs3dSceneMounting,
} from 'entities/settings';

export const Menu = () => {
  const isMinted = useAppSelector(selectIsMinted);
  const isSceneMounting = useAppSelector(selectorIs3dSceneMounting);
  const isMenu = useAppSelector(selectMenuVisability);
  const { pathname } = useLocation();
  const isGame = pathname === ROUTES.GAME;

  return (
    <Wrapper
      isOpen={isMenu}
      style={{
        pointerEvents: isSceneMounting ? 'none' : 'auto',
      }}
    >
      <NavLink
        to={isMinted ? ROUTES.LAUNCH : ROUTES.MINT}
        style={{ textDecoration: 'none' }}
      >
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive || isGame}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive || isGame}>
                <StyledIcon as={Logo} isActive={isActive || isGame} />
                SINT
              </Item>
            </Gradient>
          </Button>
        )}
      </NavLink>
      {/* <NavLink to={ROUTES.GAME} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>DEV-GAME</Item>
            </Gradient>
          </Button>
        )}
      </NavLink> */}

      <NavLink to={ROUTES.TASKS} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={TasksIcon} isActive={isActive} />
                TASKS
              </Item>
            </Gradient>
          </Button>
        )}
      </NavLink>
      <NavLink to={ROUTES.RATINGS} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={RaitingIcon} isActive={isActive} />
                RATING
              </Item>
            </Gradient>
          </Button>
        )}
      </NavLink>

      <NavLink to={ROUTES.PROFILE} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={ProfileIcon} isActive={isActive} />
                PROFILE
              </Item>
            </Gradient>
          </Button>
        )}
      </NavLink>
    </Wrapper>
  );
};
