import React, { createContext, useCallback, useEffect, useState } from 'react';
import { TelegramUser, useTelegram } from 'feature/auth';
import { AuthContextProps } from './AuthProvoider.types';
import {
  useConfirmLinkMutation,
  useLoginMutation,
  useRegisterMutation,
} from 'entities/user/model/api';

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user: telegramUser, isReady, initData, startParams } = useTelegram(); // Получаем данные пользователя из Telegram
  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();
  const [confirmLink] = useConfirmLinkMutation();
  const [user, setUser] = useState<TelegramUser | null>(null);

  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);

  // Code for development with user locally ---- DO NOT REMOVE IT ---- >>>>
  // useEffect(() => {
  //   handleAuthFlow();
  // }, [telegramUser]);

  // const handleAuthFlow = async () => {
  //   const result = await login({
  //     data: 'query_id=105713&user=%7B%22id%22%3A123123123%2C%22is_bot%22%3Afalse%2C%22is_premium%22%3Atrue%2C%22first_name%22%3A%22test%20user%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22test%20user%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1731522274&hash=298ee94d72af0b923abc2717c191e756810cfc2d23f7d5d9123075dbdb3fe8b3',
  //     startParam: startParams,
  //   }).unwrap();
  //   setUser(result);
  // };
  // Code for development with user locally ---- DO NOT REMOVE IT ---- <<<<

  const confirmInvite = async (): Promise<any> => {
    try {
      const result = await confirmLink({
        link: startParams,
      }).unwrap();
      setAuthenticated(true);
      return result; // Возвращаем результат
    } catch (error: any) {
      console.error('Login failed:', error?.status);
      setAuthenticated(false);
      throw error; // Пробрасываем ошибку для обработки в вызывающем коде
    }
  };

  /**
   * Метод для выполнения логина
   */
  const handlelogin = async (): Promise<any> => {
    try {
      const result = await login({
        data: initData,
        startParam: startParams,
      }).unwrap();
      setUser(result);
      if (result.isWaitlisted && startParams) {
        await confirmInvite();
      }
      setAuthenticated(true);
      return result; // Возвращаем результат
    } catch (error: any) {
      console.error('Login failed:', error?.status);
      setUser(null);
      setAuthenticated(false);
      throw error; // Пробрасываем ошибку для обработки в вызывающем коде
    }
  };

  const handleRegister = async (email?: string): Promise<any> => {
    // TODO remove email check
    try {
      const result = await register({
        data: initData,
        email: email,
        startParam: email ? '' : startParams,
      }).unwrap();

      setUser(result);
      setAuthenticated(true);
      return result; // Возвращаем результат
    } catch (error: any) {
      console.error('Registration failed:', error?.status);
      setUser(null);
      setAuthenticated(false);
      throw error; // Пробрасываем ошибку для обработки в вызывающем коде
    }
  };

  /**
   * Метод для общей авторизации
   */

  const auth = async () => {
    if (!isReady) return;

    try {
      const result = await handlelogin();
      return result;
    } catch (loginError: any) {
      console.error(
        'Login failed, attempting registration',
        loginError?.status
      );

      try {
        const result = await handleRegister();
        return result;
      } catch (registerError: any) {
        if (registerError.status === 400) {
          setError(true);
        }

        return registerError;
      }
    }
  };

  /**
   * Метод для выхода из системы
   */
  const logout = () => {
    setUser(null);
    setAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isTelegramReady: isReady,
        isAuthenticated,
        isError,
        user,
        tgUser: telegramUser,
        auth: auth,
        joinWaitList: handleRegister,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
