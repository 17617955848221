import { Text } from 'shared/typography';
import { Button } from 'shared/button';
import { colors } from 'app/colors';
import { Gradient } from 'shared/gradient';
import { TrophyIcon } from 'shared/icon';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import { BonusContainer } from 'shared/layout';
import { Wrapper, CheckMark, TextBlock } from './sintReadyModal.styled';

interface SintReadyModalProps {
  onClose: () => void;
}

export const SintReadyModal = ({ onClose }: SintReadyModalProps) => {
  return (
    <Wrapper>
      <CheckMark>
        <DivDecoration width={8} height={'72px'} />
        <BonusContainer width='80px' height='80px'>
          <TrophyIcon size={50} color={colors.secondaryColor} />
        </BonusContainer>
        <DivDecoration width={8} isReverse height={'72px'} />
      </CheckMark>
      <TextBlock>
        <Text style={{ fontSize: 20 }}>
          YOUR CHARACTER
          <br /> IS READY TO ROCK!
        </Text>
        <Text
          style={{ color: colors.fourthColor, letterSpacing: 2, marginTop: 10 }}
        >
          TRAIN YOUR SYNTHETIC
          <br />
          INTELLIGENCE
          <br />
          PLAY, LEARN, EVOLVE
        </Text>
      </TextBlock>
      <Button
        onClick={onClose}
        decoration='basic'
        borderColor={colors.primaryColor}
        size='l'
      >
        <Gradient color={colors.primaryColor}>
          <Text>LET'S GO</Text>
        </Gradient>
      </Button>
    </Wrapper>
  );
};
