import {
  Position,
  ModelsObjectNames,
  MaterialsForInitModifyType,
  ModelViewPoint,
} from '../types/3dTypes';

/**
 * Determines whether to enable depth settings for all materials in the model.
 * If `true`, all materials will have depthWrite and depthTest enabled.
 */
export const isMakeAllMaterialDepth = true;

/**
 * Rotation speed for model animations.
 * Used to control how fast the model rotates in specific animations or scenes.
 */
export const ROTATION_SPEED = 0.003;

/**
 * Duration (in seconds) for position transitions of the model.
 * Used in animations when the model moves between different points.
 */
export const GAME_POS_CHANGE_DURATION = 1;

/**
 * Default camera position in the 3D scene.
 * Defines the initial location of the camera before any animations or changes.
 */
export const DEFAULT_CAMERA_POSITION = { x: 0, y: 0, z: 0 };

/**
 * Default field of view (FOV) for the camera.
 * Controls the viewing angle of the camera in the 3D scene.
 */
export const DEFAULT_CAMERA_FOV = 30;

/**
 * Position of the model on the game page.
 * Defines where the model should be located when the game page is active.
 */
export const modelGamePagePos: Position = { x: 0, y: -0.5, z: -2 };

/**
 * Position of the model when the game starts.
 * Used to animate the model to its initial location in the game view.
 */
export const modelStartedGamePos: Position = { x: 0, y: -0.77, z: -1.5 };

/**
 * Position of the model on the profile page.
 * Defines where the model should be located when the profile page is active.
 */
export const modelProfilePagePos: Position = { x: 0, y: -1.1, z: 1.75 };

/**
 * Default position of the model in the scene.
 * Used as the initial or reset position for the model.
 */
export const modelDefaultPos: Position = { x: 0, y: -0.6, z: 0 };

/**
 * Default scale of the model.
 * Controls the size of the model in the 3D scene.
 */
export const modelDefaultScale: Position = { x: 0.73, y: 0.73, z: 0.73 };

/**
 * Names of specific objects in the 3D model.
 * These names are used to reference parts of the model for animations or interactions.
 */
export const objNames: Record<ModelsObjectNames, string> = {
  sint: 'Armature', // Main model object
  hologram: 'ArmatureHologram', // Hologram object associated with the model
};

/**
 * List of material names that need to be modified during the model's initialization.
 * These materials will have specific settings applied for effects like blending.
 */
export const materialForInitPreparing: MaterialsForInitModifyType = [
  'tummy', // Material for the tummy area
  'hand', // Material for the hand area
  'ankle', // Material for the ankle area
];

/**
 * Array of viewpoints for the model during the game.
 * Each viewpoint defines a specific position and rotation the model can transition to.
 */
export const modelGameViewPoints: ModelViewPoint[] = [
  {
    rotation: { x: 0, y: Math.PI / 10, z: 0 },
    position: { x: 0.15, y: -1, z: 1.75 },
  },
  {
    rotation: { x: 0, y: -Math.PI / 10, z: 0 },
    position: { x: -0.15, y: -1, z: 1.75 },
  },
  {
    rotation: { x: 0, y: 0, z: 0 },
    position: { x: 0, y: -1.05, z: 2 },
  },
  {
    rotation: { x: 0, y: Math.PI / 4, z: 0 },
    position: { x: 0.1, y: -0.55, z: 2 },
  },
  {
    rotation: { x: 0, y: -Math.PI / 4, z: 0 },
    position: { x: -0.1, y: -0.55, z: 2 },
  },
  {
    rotation: { x: 0, y: Math.PI * 1.25, z: 0 },
    position: { x: 0, y: -1.05, z: 1.9 },
  },
];
