import { FC } from 'react';
import { SoonProps } from './soon.types';
import { Container, Title, Text } from './Soon.styled';
import { SoonIcon } from 'shared/icon';

export const Soon: FC<SoonProps> = ({ color = '#ffffff', title, descr }) => (
  <Container>
    <SoonIcon color={color} />
    <Title>{title}</Title>
    <Text>{descr}</Text>
  </Container>
);
