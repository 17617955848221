import * as React from 'react';
import { IconProps } from '../icon.types';

export const TabsButtonDecoration: React.FC<IconProps> = ({
  color = '#7F64F2',
  width,
  height,
  ...props
}) => (
  <svg
    width={width || '96'}
    height={height || '40'}
    viewBox='0 0 96 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M0.5 7.18496L6.22997 0.5H89.77L95.5 7.18497V32.815L89.77 39.5H6.22997L0.5 32.815V7.18496Z'
      fill='url(#paint0_radial_8731_3094)'
      stroke='#7F64F2'
    />
    <defs>
      <radialGradient
        id='paint0_radial_8731_3094'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(48 20) rotate(90) scale(20 48)'
      >
        <stop stop-color='#7F64F2' />
        <stop offset='1' stop-color='#7F64F2' stop-opacity='0.1' />
      </radialGradient>
    </defs>
  </svg>
);
