import * as React from 'react';
import { IconProps } from '../icon.types';

export const CoinIcon: React.FC<IconProps> = ({
  color = '#271F4E',
  width,
  height,
  size = 20,
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    {...props}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M39.9031 25.4199C41.2838 27.0546 41.9942 28.8565 41.9942 30.667C41.9942 33.4722 40.2889 36.2565 37.0277 38.4308C33.7805 40.5959 29.1793 42 23.9971 42C18.8149 42 14.2137 40.5959 10.9665 38.4308C7.70534 36.2565 6 33.4722 6 30.667L4 30.6667C4 38.0303 12.953 44 23.9971 44C35.0412 44 43.9942 38.0306 43.9942 30.667C43.9942 23.3035 35.0412 17.3341 23.9971 17.3341C17.5137 17.3341 11.7508 19.3913 8.09695 22.5802C6.71628 20.9454 6.00584 19.1435 6.00584 17.333C6.00584 14.5278 7.71118 11.7435 10.9724 9.56917C14.2196 7.40412 18.8207 6 24.003 6C29.1852 6 33.7864 7.40412 37.0336 9.56917C40.2947 11.7435 42.0001 14.5278 42.0001 17.333L44.0001 17.3333C44.0001 9.96967 35.0471 4 24.003 4C12.9589 4 4.00584 9.96937 4.00584 17.333C4.00584 24.6965 12.9589 30.6659 24.003 30.6659C30.4864 30.6659 36.2492 28.6087 39.9031 25.4199ZM38.486 24.0025C38.0409 23.6218 37.5546 23.2546 37.0277 22.9033C33.7805 20.7382 29.1793 19.3341 23.9971 19.3341C18.8149 19.3341 14.2137 20.7382 10.9665 22.9033C10.4419 23.253 9.95756 23.6186 9.5141 23.9975C9.95915 24.3782 10.4454 24.7454 10.9724 25.0967C14.2196 27.2618 18.8207 28.6659 24.003 28.6659C29.1852 28.6659 33.7864 27.2618 37.0336 25.0967C37.5582 24.747 38.0425 24.3814 38.486 24.0025Z'
      fill={color}
    />
  </svg>
);
