import { Balance } from 'feature/game/balance';
import { MainGame } from 'feature/game';

import { Header, PageContent, PageWrapper } from 'shared/layout';

import { Menu } from 'widget/menu';
import { selectMenuVisability } from 'entities/settings';
import { useAppSelector } from 'app/store/rootStore';

export const Game = () => {
  const isMenu = useAppSelector(selectMenuVisability);
  console.log(isMenu);
  return (
    <PageWrapper>
      <Header>
        <div />
        <Balance />
        <div />
      </Header>
      <PageContent style={{ overflow: 'unset' }} isMenu={isMenu}>
        <MainGame />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
