import { Balance } from 'feature/game/balance';
import { Level } from 'feature/level';
import { Header, PageContent, PageWrapper } from 'shared/layout';
import { FakeMint } from 'widget/fakeMint';
import { Menu } from 'widget/menu';

export const Mint = () => {
  return (
    <PageWrapper>
      <Header>
        <div />
        <Balance />
        <div />
      </Header>
      <PageContent>
        <FakeMint />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
