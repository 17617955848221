import { Text } from 'shared/typography';
import styled from 'styled-components';

export const CheckMark = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
