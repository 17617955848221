import { useAppSelector } from 'app/store/rootStore';

import { EnergyIcon } from 'shared/icon';

import { EnergyText, Wrapper } from './Enegry.styled';
import { CentredWrapper } from 'shared/layout';
import { Counter } from 'shared/counter';
import { selectEnergy } from 'entities/user';
import { useModal } from 'shared/modal';
import { useEffect } from 'react';

export const Energy = () => {
  const energy = useAppSelector(selectEnergy);

  return (
    <Wrapper>
      <CentredWrapper>
        <EnergyIcon />
      </CentredWrapper>
      <EnergyText>
        <Counter
          startValueOnce={true}
          startValue={energy}
          key={'ENERGY'}
          dummyCharacters={['x']}
          value={energy + '/500'}
        />
      </EnergyText>
    </Wrapper>
  );
};
