import styled from 'styled-components';

export const StyledBackButton = styled.div`
  display: flex;
  z-index: 1;
  align-items: center;
  cursor: pointer;
  width: 48px;
  height: 48px;
`;
