// Soon.styled.ts
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
  height: calc(100% - 53px);
  gap: 18px;
`;

export const Title = styled.div`
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
`;

export const Text = styled.div`
  max-width: 272px;
  font-weight: 400;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
`;
