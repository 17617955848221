import { TonConnectButton } from '@tonconnect/ui-react';
import { ROUTES } from 'app';
import { colors } from 'app/colors';
import { RobotScene } from 'feature/3d';
import { Balance } from 'feature/game/balance';
import { ConnectWallet } from 'feature/web3';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { WalletIcon } from 'shared/icon';
import { H100, Header, PageContent, PageWrapper } from 'shared/layout';
import { Menu } from 'widget/menu';
import { ProfileContent } from 'widget/profile';

export const Profile = () => {
  return (
    <PageWrapper>
      <Header>
        <Button
          disabled={true}
          size='xs'
          style={{ visibility: 'hidden', width: '48px' }}
        ></Button>
        <Balance />
        <NavLink to={ROUTES.WALLET}>
          <Button
            decoration='basic'
            size='s'
            borderColor={colors.primaryColor}
            borderSize={0}
            style={{ width: '48px', height: '48px' }}
          >
            <Gradient>
              <WalletIcon />
            </Gradient>
          </Button>
        </NavLink>
      </Header>
      <PageContent>
        <ProfileContent />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
