// src/entities/tasks/api/tasksApi.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  TaskApiResponse,
  TaskClaimResponse,
  TaskCompleteResponse,
} from './tasks.types';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    fetchMainTasks: builder.mutation<TaskApiResponse, void>({
      query: () => '/main-quests',
    }),
    fetchDailyTasks: builder.mutation<TaskApiResponse, void>({
      query: () => '/daily-quest',
    }),
    fetchPartnerTasks: builder.mutation<TaskApiResponse, void>({
      query: () => '/partners-quests',
    }),
    completeMainQuest: builder.mutation<TaskCompleteResponse, number>({
      query: (id) => ({
        url: `/main-quests/${id}/complete`,
        method: 'POST',
      }),
    }),
    completePartnerQuest: builder.mutation<TaskCompleteResponse, number>({
      query: (id) => ({
        url: `/partners-quests/${id}/complete`,
        method: 'POST',
      }),
    }),
    claimReward: builder.mutation<TaskClaimResponse, number>({
      query: (accountQuestId) => ({
        url: `/main-quests/${accountQuestId}/claim-reward`,
        method: 'POST',
      }),
    }),
    claimPartnerReward: builder.mutation<TaskClaimResponse, number>({
      query: (accountQuestId) => ({
        url: `/partners-quests/${accountQuestId}/claim-reward`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useFetchMainTasksMutation,
  useFetchDailyTasksMutation,
  useFetchPartnerTasksMutation,
  useCompleteMainQuestMutation,
  useClaimRewardMutation,
  useCompletePartnerQuestMutation,
  useClaimPartnerRewardMutation,
} = tasksApi;
