// pages/Tasks.tsx
import { PageTitle } from 'shared/typography';
import { Header, PageContent, PageWrapper } from 'shared/layout';
import { Tasks } from 'widget/Tasks';
import { Menu } from 'widget/menu';

export const TaskPage = () => {
  return (
    <PageWrapper>
      <Header>
        <div></div>
        <PageTitle>Tasks</PageTitle>
        <div></div>
      </Header>
      <PageContent>
        <Tasks />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
