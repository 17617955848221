// Modal.styled.ts
import styled, { keyframes } from 'styled-components';
import { ModalProps } from './modal.types';

const fadeIn = keyframes`
  0% {
    opacity: 0; 
  }
  100% {
    opacity: 1; 
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1; 
  }
  100% {
    opacity: 0; 
  }
`;

const slideInFromBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOutToBottom = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const StyledModal = styled.div<Pick<ModalProps, 'isClosing'>>`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: end;
  animation: ${({ isClosing }) => (isClosing ? fadeOut : fadeIn)} 0.3s ease-out
    forwards;
  background: #0000004c;
  opacity: ${({ isClosing }) => (isClosing ? 0 : 1)};
  transition: opacity 0.3s ease-out;
`;

export const ModalContent = styled.div<Pick<ModalProps, 'types' | 'isClosing'>>`
  background: white;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 55px 0;
  position: relative;
  border-radius: 24px 24px 0px 0px;
  animation: ${({ isClosing }) =>
      isClosing ? slideOutToBottom : slideInFromBottom}
    0.3s ease-out forwards;
  background: ${(props) =>
    props.types === 'success'
      ? 'rgba(0, 128, 0)'
      : props.types === 'error'
        ? 'rgba(255, 0, 0)'
        : props.types === 'warn'
          ? 'rgba(255, 255, 0)'
          : 'var(--gradient)'};
  opacity: ${({ isClosing }) => (isClosing ? 0 : 1)};
  transform: ${({ isClosing }) =>
    isClosing ? 'translateY(100%)' : 'translateY(0)'};
`;

export const ModalClose = styled.button`
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
