import React, { useState, KeyboardEvent } from 'react';
import {
  StyledInputWrapper,
  StyledInputField,
  StyledIconContainer,
} from './Input.styled';
import { InputProps } from './input.types';

export const Input: React.FC<InputProps> = ({
  value,
  color,
  bgColor,
  placeholderColor,
  onChange,
  placeholder,
  type,
  icon: Icon,
  disabled = false,
  onEnter,
  onSubmit,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Return') {
      onEnter?.(value);
      (e.target as HTMLInputElement).blur(); // Закрытие клавиатуры
    }
  };

  return (
    <StyledInputWrapper
      isActive={isActive || Boolean(value)}
      disabled={disabled}
      bgColor={bgColor}
    >
      <StyledInputField
        type={type}
        color={color}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        onKeyUp={handleKeyUp}
        disabled={disabled}
      />
      {Icon && (
        <StyledIconContainer
          isActive={isActive}
          disabled={disabled}
          onClick={() => !disabled && onSubmit?.(value)}
        >
          <Icon />
        </StyledIconContainer>
      )}
    </StyledInputWrapper>
  );
};
