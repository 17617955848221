import styled from 'styled-components';

export const SceneContainer = styled.div<{
  width?: string;
  height?: string;
}>`
  position: relative;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  background: transparent;
`;
