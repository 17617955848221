import * as React from 'react';
import { IconProps } from './icon.types';
import { colors } from 'app/colors';

export const WalletIcon: React.FC<IconProps> = ({
  color = colors.whiteColor,
  width,
  height,
  size = 24,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.708 7.38867H5.2915C4.44141 7.38867 3.75 6.79688 3.75 6.06934C3.75 5.3418 4.44141 4.75 5.2915 4.75H16.792C16.999 4.75 17.167 4.58203 17.167 4.375C17.167 4.16797 16.999 4 16.792 4H5.2915C4.02783 4 3 4.92822 3 6.06934V17.9307C3 19.0713 4.02783 20 5.2915 20H18.708C19.9717 20 21 19.0713 21 17.9307V9.4585C21 8.31738 19.9717 7.38867 18.708 7.38867ZM20.25 17.9307C20.25 18.6582 19.5586 19.25 18.708 19.25H5.2915C4.44141 19.25 3.75 18.6582 3.75 17.9307V7.59912C4.15723 7.93408 4.69824 8.13867 5.2915 8.13867H18.708C19.5586 8.13867 20.25 8.73096 20.25 9.4585V17.9307ZM17.5 13.75C17.5 14.1642 17.1642 14.5 16.75 14.5C16.3358 14.5 16 14.1642 16 13.75C16 13.3358 16.3358 13 16.75 13C17.1642 13 17.5 13.3358 17.5 13.75Z'
      fill={color}
    />
  </svg>
);
