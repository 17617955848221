import { colors } from 'app/colors';
import { RobotScene } from 'feature/3d';
import { useRef, useState } from 'react';
import { useInterval } from 'react-use';
import type { FC } from 'react';
import { Button } from 'shared/button';
import { Counter } from 'shared/counter';
import { GradientTimer } from 'shared/gradientTimer';
import { useModal } from 'shared/modal';
import { useToast } from 'shared/toast/ToastContext';
import { ProgressBar } from 'shared/progress';
import { Timer } from 'shared/timer';
import { Balance } from 'feature/game/balance';
import { Carousel } from 'shared/carousel';
import { Tabs } from 'shared/tabs';
import { Energy } from 'feature/game/energy';
import { Copy } from 'shared/copy';

import { Step, StepStatus, StepDirection } from 'shared/steps/Steps.types'; // Import Step type
import { Steps } from 'shared/steps';
import { Gradient } from 'shared/gradient';

import { Input } from 'shared/input';
import { InputIcon } from 'shared/icon';

const slides = Array.from({ length: 6 }, (_, index) => (
  <div
    key={index}
    style={{
      backgroundColor: `hsl(${index * 60}, 70%, 50%)`,
      height: '200px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      fontSize: '24px',
    }}
  >
    Slide {index + 1}
  </div>
));

export const SandBox: FC = () => {
  const { isOpen, setIsOpen, setModalProps } = useModal();
  const { showToast } = useToast();
  const carouselRef = useRef<any>(null); // Реф для управления каруселью

  const [textInputValue, setTextInputValue] = useState<string>('');
  const [passwordInputValue, setPasswordInputValue] = useState<string>('');

  const handleSubmit = (value?: string) => {
    if (value) {
      console.log('Submitted value:', value);
    } else {
      console.log('No value submitted');
    }
  };

  const [sequenceSteps, setSequenceSteps] = useState<Step[]>([
    {
      label: 'Step 1',
      value: 'step1',
      color: colors.fillBarColor,
    },
    {
      label: 'Step 2',
      value: 'step2',
      color: colors.greenColor,
    },
    {
      label: 'Step 3',
      value: 'step3',
      color: colors.fillBarColor,
    },
    {
      label: 'Step 1',
      value: 'step1',

      color: colors.greenColor,
    },
    {
      label: 'Step 1',
      value: 'step1',

      color: colors.primaryColor,
    },
    {
      label: 'Step 1',
      value: 'step1',

      color: colors.primaryColor,
    },
  ]);

  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    setSequenceSteps((prevSteps) => {
      if (currentStep >= 0 && currentStep < prevSteps.length) {
        const updatedSteps = prevSteps.map((step, index) => {
          if (index === currentStep) {
            return { ...step, status: StepStatus.Success };
          } else if (index === currentStep + 1) {
            return { ...step, status: StepStatus.Active };
          }
          return step;
        });

        if (currentStep < prevSteps.length - 1) {
          setCurrentStep((prev) => prev + 1);
        }

        return updatedSteps;
      }
      return prevSteps;
    });
  };

  const handlePrevious = () => {
    setSequenceSteps((prevSteps) => {
      if (currentStep > 0 && currentStep < prevSteps.length) {
        const updatedSteps = prevSteps.map((step, index) => {
          if (index === currentStep) {
            return { ...step, status: StepStatus.Active };
          } else if (index === currentStep - 1) {
            return { ...step, status: StepStatus.Active };
          }
          return step;
        });

        setCurrentStep((prev) => prev - 1);
        return updatedSteps;
      }
      return prevSteps;
    });
  };

  const gamingSteps: Step[] = [
    {
      label: 'Game 1',
      value: 'game1',
      content: '0',
      width: '68px',
      height: '68px',
      color: colors.primaryColor,
      timer: 10,
      fontSize: '24px',
    },
    {
      label: 'Game 2',
      value: 'game2',
      content: '1',
      width: '56px',
      height: '56px',
      color: colors.secondaryColor,
      timer: 8,
      fontSize: '18px',
    },
    {
      label: 'Game 3',
      value: 'game3',
      content: '1',
      width: '44px',
      height: '44px',
      color: colors.greenColor,
      timer: 6,
      fontSize: '14px',
    },
    {
      label: 'Game 1',
      value: 'game1',
      content: '0',
      width: '68px',
      height: '68px',
      color: colors.greenColor,
      timer: 10,
      fontSize: '24px',
    },
    {
      label: 'Game 1',
      value: 'game1',
      content: '0',
      width: '68px',
      height: '68px',
      color: colors.greenColor,
      timer: 10,
      fontSize: '24px',
    },
    {
      label: 'Game 1',
      value: 'game1',
      content: '0',
      width: '68px',
      height: '68px',
      color: colors.greenColor,
      timer: 10,
      fontSize: '24px',
    },
  ].map((step) => ({
    ...step,
    status: StepStatus.Active,
  }));

  const tabItems = [
    {
      title: 'social',
      segment: <div>social tasks</div>,
      disable: false,
    },
    {
      title: 'daily',
      segment: <div>daily tasks</div>,
      disable: false,
    },
    {
      title: 'partners',
      segment: <div>partners tasks</div>,
      disable: false,
    },
  ];

  const handleToggleModal = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setModalProps({
        onRequestClose: () => setIsOpen(false),
        children: (
          <div>
            <p>test modal</p>
          </div>
        ),
      });
      setIsOpen(true);
    }
  };

  const handleSuccess = () => {
    showToast('done', 'success');
  };

  const handleError = () => {
    showToast('error', 'error');
  };

  const handleWarning = () => {
    showToast('warning', 'warn');
  };

  const handleSoon = () => {
    showToast('coming soon...', 'soon');
  };

  const targetDate = new Date('2024-12-31T00:00:00Z');
  const textToCopy = 'Sample text to copy';

  return (
    <div>
      <div style={{ background: '', overflow: 'scroll' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button borderColor={colors.primaryColor} size='l'>
            <Gradient color={colors.primaryColor}>Button l</Gradient>
          </Button>
          <Energy />
          <Button
            borderColor={colors.secondaryColor}
            decoration='basic'
            size='m'
          >
            Button m
          </Button>
          <Button
            borderColor={colors.secondaryColor}
            decoration='reverse'
            size='m'
          >
            <Gradient color={colors.secondaryColor}>Button m</Gradient>
          </Button>
          <Button
            borderColor={colors.whiteColor}
            color={colors.whiteColor}
            decoration='reverse'
            size='m'
          >
            <div style={{ color: 'black' }}>Check</div>
          </Button>
          <Button borderColor={colors.primaryColor} size='s'>
            Button s
          </Button>
          <Button size='l' shape='round'>
            <GradientTimer
              color={colors.primaryColor}
              borderWidth={15}
              content={1}
              isBlum={false}
              status={'active'}
            />
          </Button>
          <Button size='m' borderColor={colors.secondaryColor} shape='round'>
            <GradientTimer
              color={colors.secondaryColor}
              borderWidth={15}
              content={1}
              isBlum={false}
              status='failed'
            />
          </Button>
          <Button size='s' borderColor={colors.primaryColor} shape='round'>
            <Gradient color={colors.primaryColor}>3</Gradient>
          </Button>
          <Button size='m' shape='round'>
            <Gradient isFull={false} color={colors.primaryColor}>
              ico
            </Gradient>
          </Button>

          <div style={{ width: '300px' }}>
            <Input
              type='text'
              value={textInputValue}
              onChange={(e) => setTextInputValue(e.target.value)}
              placeholder='Text Input'
              icon={InputIcon}
              onSubmit={() => handleSubmit(textInputValue)}
            />
            <Input
              type='password'
              value={passwordInputValue}
              onChange={(e) => setPasswordInputValue(e.target.value)}
              placeholder='Password Input'
              icon={InputIcon}
              onSubmit={() => handleSubmit(passwordInputValue)}
            />
          </div>
          {/* Copy component */}
          <Copy textToCopy={textToCopy}>
            <Button size='m' shape='round'></Button>
          </Copy>
        </div>

        <br />

        <Button
          onClick={handleToggleModal}
          borderColor={colors.primaryColor}
          size='l'
        >
          <Gradient color={colors.primaryColor}>open modal</Gradient>
        </Button>

        <Button
          borderColor={colors.primaryColor}
          size='l'
          onClick={handleSuccess}
        >
          Success
        </Button>
        <Button
          borderColor={colors.primaryColor}
          size='l'
          onClick={handleError}
        >
          Error
        </Button>
        <Button
          borderColor={colors.primaryColor}
          size='l'
          onClick={handleWarning}
        >
          Warning
        </Button>
        <Button borderColor={colors.primaryColor} size='l' onClick={handleSoon}>
          Warning
        </Button>

        <div style={{ marginTop: '40px' }}>
          {' '}
          <ProgressBar current={70} target={100} />
        </div>
        <br />
        <Timer targetDate={targetDate} />
        <Balance />
        <br />
        <div style={{ width: '80%', margin: '0 auto' }}>
          <h2>Custom Carousel</h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            {/* Кнопки управления */}
            <Button
              borderColor={colors.primaryColor}
              onClick={() => carouselRef.current?.previous()}
            >
              Previous
            </Button>
            <Button
              borderColor={colors.primaryColor}
              onClick={() => carouselRef.current?.next()}
            >
              Next
            </Button>
          </div>
          <Carousel
            arrows={false}
            enableGradient={true}
            draggable={false}
            ref={carouselRef}
          >
            {slides}
          </Carousel>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {/* Sequence Steps */}
          <div
            style={{
              margin: '0px 50%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                color: '#fff',
              }}
            >
              Sequence Steps
            </p>
          </div>

          {/* Navigation Buttons */}
          <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
            <Button
              borderColor={colors.primaryColor}
              size='l'
              onClick={handlePrevious}
            >
              Previous
            </Button>
            <Button
              borderColor={colors.primaryColor}
              size='l'
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            borderColor={colors.primaryColor}
            size='l'
            onClick={handleSuccess}
          >
            Success
          </Button>
          <Button
            borderColor={colors.primaryColor}
            size='l'
            onClick={handleError}
          >
            Error
          </Button>
          <Button
            borderColor={colors.primaryColor}
            size='l'
            onClick={handleWarning}
          >
            Warning
          </Button>
          <Button
            borderColor={colors.primaryColor}
            size='l'
            onClick={handleSoon}
          >
            Coming Soon
          </Button>
        </div>
        <div style={{ marginTop: '40px' }}>
          {' '}
          <ProgressBar current={70} target={100} />
        </div>
        <br />
        <Timer targetDate={targetDate} />
        <Balance />
      </div>
      <RobotScene />
    </div>
  );
};
