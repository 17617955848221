// widget/Tasks/Tasks.tsx
import { FC, useEffect } from 'react';
import { useAppSelector } from 'app/store/rootStore';
import { selectTasks, TasksContent } from 'entities/tasks';
import {
  useFetchDailyTasksMutation,
  useFetchMainTasksMutation,
  useFetchPartnerTasksMutation,
} from 'entities/tasks/api';
import { H100 } from 'shared/layout';

import { Tabs } from 'shared/tabs';
import { Loader } from 'shared/loader';

export const Tasks: FC = () => {
  const [fetchMainTasks, { isLoading: loading }] = useFetchMainTasksMutation();
  const [fetchDailyTasks, { isLoading: isDailyLoading }] =
    useFetchDailyTasksMutation();
  const [fetchPartnerTasks, { isLoading: isPartnerLoading }] =
    useFetchPartnerTasksMutation();
  const taskState = useAppSelector(selectTasks);

  useEffect(() => {
    fetchMainTasks();
    fetchDailyTasks();
    fetchPartnerTasks();
  }, []);

  const isMainData = taskState.mainTasks.data.length > 0;
  const isDailyData = taskState.dailyTasks.data.length > 0;
  const isPartnerData = taskState.partnerTasks.data.length > 0;

  const tabItems = [
    {
      title: 'social',
      segment:
        !loading || isMainData ? (
          <TasksContent
            case={'fetchMainTask'}
            items={taskState.mainTasks.data}
          />
        ) : (
          <H100>
            <Loader />
          </H100>
        ),
      disable: false,
    },
    {
      title: 'daily',
      segment:
        !isDailyLoading || isDailyData ? (
          <TasksContent
            case={'fetchDailyTask'}
            items={taskState.dailyTasks.data}
          />
        ) : (
          <H100>
            <Loader />
          </H100>
        ),
      disable: false,
    },
    {
      title: 'partners',
      segment:
        !isPartnerLoading || isPartnerData ? (
          <TasksContent
            case={'fetchPartnerTask'}
            items={taskState.partnerTasks.data}
          />
        ) : (
          <H100>
            <Loader />
          </H100>
        ),
      disable: false,
    },
  ];

  return <Tabs defaultActiveTab='social' items={tabItems} />;
};
