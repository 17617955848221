import { FC } from 'react';
import { QRWrapper } from './QrCode.styled';
import { QRcode } from 'shared/icon/QRcode/QRcode';
import { BlockWrapper, BlockContent, CentredWrapper } from 'shared/layout';
import { Text } from 'shared/typography';

export const QRContent: FC = () => {
  return (
    <QRWrapper>
      <CentredWrapper>
        <BlockWrapper>
          <BlockContent>
            <Text
              style={{
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '28px',
              }}
            >
              LEAVE THE DESKTOP
            </Text>
            <Text
              style={{
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '28px',
              }}
            >
              MOBILE GAMING
            </Text>
            <Text
              style={{
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '28px',
              }}
            >
              ROCKS!
            </Text>
          </BlockContent>
        </BlockWrapper>
        <BlockWrapper>
          <BlockContent style={{ marginTop: '34px' }}>
            <QRcode width={176} height={176} />
          </BlockContent>
        </BlockWrapper>
      </CentredWrapper>
    </QRWrapper>
  );
};
