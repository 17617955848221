// src/entities/tasks/api/tasksSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tasksApi } from './api';
import { TasksState } from './tasks.types';
import { RootState } from 'app/store/rootStore';

const initialState: TasksState = {
  mainTasks: { data: [] },
  dailyTasks: { data: [] },
  partnerTasks: { data: [] },
  loading: false,
  completedTaskIds: [],
  completedPartnerIds: [],
  rewardTaskIds: [],
  rewardPartnerIds: [],
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTaskComplete: (state, action: PayloadAction<string>) => {
      if (!state.completedTaskIds.includes(action.payload)) {
        state.completedTaskIds.push(action.payload);
      }
    },
    setPartnerComplete: (state, action: PayloadAction<string>) => {
      if (!state.completedPartnerIds.includes(action.payload)) {
        state.completedPartnerIds.push(action.payload);
      }
    },
    setRewardComplete: (state, action: PayloadAction<string>) => {
      if (!state.rewardTaskIds.includes(action.payload)) {
        state.rewardTaskIds.push(action.payload);
      }
    },
    setPartnerReward: (state, action: PayloadAction<string>) => {
      if (!state.rewardPartnerIds.includes(action.payload)) {
        state.rewardPartnerIds.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        tasksApi.endpoints.fetchMainTasks.matchFulfilled,
        (state, action) => {
          state.mainTasks = action.payload;
          state.loading = false;
        }
      )

      .addMatcher(
        tasksApi.endpoints.fetchDailyTasks.matchFulfilled,
        (state, action) => {
          state.dailyTasks = action.payload;
          state.loading = false;
        }
      )
      .addMatcher(
        tasksApi.endpoints.fetchPartnerTasks.matchFulfilled,
        (state, action) => {
          state.partnerTasks = action.payload;
          state.loading = false;
        }
      )
      .addMatcher(tasksApi.endpoints.fetchMainTasks.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(tasksApi.endpoints.fetchDailyTasks.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        tasksApi.endpoints.fetchPartnerTasks.matchPending,
        (state) => {
          state.loading = true;
        }
      );
  },
});

export const {
  setLoading,
  setTaskComplete,
  setPartnerComplete,
  setRewardComplete,
  setPartnerReward,
} = tasksSlice.actions;

export const selectTasks = (state: RootState) => state.tasks;
export const selectCompletedTaskIds = (state: RootState) =>
  state.tasks.completedTaskIds;
export const selectPartnerTaskIds = (state: RootState) =>
  state.tasks.completedPartnerIds;
export const selectRewardTaskIds = (state: RootState) =>
  state.tasks.rewardPartnerIds;
export const selectPartnerRewardIds = (state: RootState) =>
  state.tasks.rewardTaskIds;
export const selectFetchDailyTasks = (state: RootState) =>
  state.tasks.dailyTasks;
export default tasksSlice.reducer;
