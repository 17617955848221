import { FC, useCallback } from 'react';
import { StepsContainer, StepItem, StepsContent } from './Steps.styled';
import { CustomStepsProps, StepDirection } from './Steps.types';
import { GradientTimer } from 'shared/gradientTimer';
import { colors } from 'app/colors';
import { AnimatedBorder } from 'shared/AnimatedBorder';

interface StepsWithTimerProps extends CustomStepsProps {
  gameTime: number;
}

export const Steps: FC<StepsWithTimerProps> = ({
  steps,
  direction = StepDirection.Horizontal,
  isActive = false,
  currentStep,
  isFailed,
  gameTime,
}) => {
  const renderSteps = useCallback(
    () =>
      steps.map((step, index) => {
        const gradientColor = step.color || colors.primaryColor;

        // Логика определения статуса
        let status: 'success' | 'failed' | 'active';
        if (isFailed) {
          status = 'failed';
        } else if (!isActive) {
          status = 'failed';
        } else if (index < currentStep) {
          status = 'success';
        } else {
          status = 'active';
        }

        return (
          <StepItem key={index} status={status} color={gradientColor}>
            {status !== 'failed' ? (
              <GradientTimer
                color={gradientColor}
                static
                borderWidth={status === 'success' ? 5 : 15}
                content={step.label}
                status={status}
              />
            ) : (
              <div>x</div>
            )}
          </StepItem>
        );
      }),
    [steps, isActive, currentStep, isFailed]
  );

  return (
    <AnimatedBorder gameTime={gameTime} isClockwise={false}>
      <StepsContainer isActive={isActive} direction={direction}>
        <StepsContent direction={direction}>{renderSteps()}</StepsContent>
      </StepsContainer>
    </AnimatedBorder>
  );
};
