import { colors } from 'app/colors';
import { IconProps } from './icon.types';

export const CheckmarkIcon: React.FC<IconProps> = ({
  color = colors.fourthColor,
  width = 16,
  height = 16,
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 48 48'
    fill='none'
    {...props}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M40.5303 11.4697C40.8232 11.7626 40.8232 12.2374 40.5303 12.5303L18.5303 34.5303C18.2374 34.8232 17.7626 34.8232 17.4697 34.5303L7.46967 24.5303C7.17678 24.2374 7.17678 23.7626 7.46967 23.4697C7.76256 23.1768 8.23744 23.1768 8.53033 23.4697L18 32.9393L39.4697 11.4697C39.7626 11.1768 40.2374 11.1768 40.5303 11.4697Z'
      fill={color}
    />
  </svg>
);
