import * as React from 'react';
import { IconProps } from './icon.types';
import { colors } from 'app/colors';

export const FireIcon: React.FC<IconProps> = ({
  color = colors.secondaryColor,
  width,
  height,
  size = 48,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M27.6519 6.08799C33.3854 12.1123 38.5 18.8919 38.5 27.484C38.5 31.4455 36.946 35.3218 34.2364 38.1189C31.4995 40.9441 27.8642 42.5 24 42.5C20.1359 42.5 16.5005 40.9441 13.7637 38.1189C11.0142 35.2807 9.5 31.5039 9.5 27.484C9.5 23.766 11.0308 19.4175 14.0517 14.5499L19.945 18.6055L21.4117 19.6148L22.1575 17.9982L27.6519 6.08799ZM27.4224 4C27.381 4 27.3396 4.00364 27.2986 4.01094C27.184 4.03138 27.0758 4.07989 26.983 4.15246C26.8902 4.22502 26.8158 4.31948 26.7658 4.42792L20.7954 17.3698L14.2282 12.8505C14.1486 12.7957 14.0593 12.7576 13.9655 12.7385C13.919 12.729 13.8718 12.7243 13.8247 12.7243C13.7766 12.7243 13.7286 12.7292 13.6813 12.739C13.5875 12.7585 13.4983 12.7969 13.419 12.852C13.3396 12.9071 13.2715 12.9778 13.2187 13.0601C9.75586 18.4486 8 23.3016 8 27.484C8 31.8644 9.6857 36.0652 12.6863 39.1626C15.6869 42.26 19.7565 44 24 44C28.2434 44 32.3132 42.26 35.3138 39.1626C38.3142 36.0652 40 31.8644 40 27.484C40 17.9635 34.2018 10.6762 27.9362 4.21934C27.8538 4.13449 27.753 4.0713 27.642 4.03504C27.5707 4.01174 27.4966 4 27.4224 4Z'
      fill={color}
    />
  </svg>
);
