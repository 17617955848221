import { colors } from 'app/colors';
import styled from 'styled-components';

export const StyledToast = styled.div`
  & .Toastify__toast-container {
    padding-top: var(--tg-safe-area-inset-top);
    margin-top: var(--tg-content-safe-area-inset-top);
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: column;
    gap: 5px;
  }

  & .Toastify__toast {
    margin-top: 10px;

    padding: 15px;

    clip-path: ${colors.colorTrapezoidShape};
  }

  & .Toastify__toast-body {
    font-size: 16px;
    font-family: Titillium Web;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding: 0;
  }

  & .Toastify__toast-icon {
    margin-bottom: 2px;
  }
  & .Toastify__toast-theme--dark {
    background: linear-gradient(90.72deg, #c7acff 0%, #ffffff 87.06%);
    align-items: center;
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  }

  & .Toastify__toast--success {
    /* color: ${colors.status.success}; */
    color: ${colors.blackColor};
  }

  & .Toastify__toast--error {
    /* color: ${colors.status.error}; */
    color: ${colors.blackColor};
  }

  & .Toastify__toast--warning {
    color: ${colors.blackColor};
    /* color: ${colors.status.warning}; */
  }

  & .Toastify__toast--soon {
    color: ${colors.blackColor};
  }
`;

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
