import {
  PersonalityIcon,
  IntelligenceIcon,
  BatteryIcon,
  DesignIcon,
} from 'shared/icon';
import { CharacterStats } from './CharacterStats.types';

export const CHARACTER_STATS: CharacterStats[] = [
  {
    id: 'personality',
    name: 'PERSONALITY',
    icon: PersonalityIcon,
    data: '...',
  },
  {
    id: 'intelligence',
    name: 'INTELLIGENCE',
    icon: IntelligenceIcon,
    data: '...',
  },
  {
    id: 'battery',
    name: 'BATTERY',
    icon: BatteryIcon,
    data: '...',
  },
  {
    id: 'design',
    name: 'DESIGN',
    icon: DesignIcon,
    data: '...',
  },
];
