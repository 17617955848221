export const generateShareURL = (link: string, text: string): string => {
  const encodedLink = encodeURIComponent(link);
  const encodedText = encodeURIComponent(text);
  return `https://t.me/share/url?url=${encodedLink}&text=${encodedText}`;
};

export const generateReferralLink = (id: number | string): string => {
  const bot = process.env.REACT_APP_BOT_USERNAME;
  const botName = process.env.REACT_APP_BOT_NAME;

  return `https://t.me/${bot}/${botName}?startapp=${id}`;
};

export const getVisibleReferralLink = (link: string): string => {
  const visibleLink = link
    .replace('?startapp', '')
    .replace('?start', '')
    .replace('https://t.me/', '');
  return `${visibleLink}`;
};
