import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { FireIcon } from 'shared/icon';
import { CheckmarkIcon } from 'shared/icon/Checkmark';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import {
  BlockContent,
  BonusContainer,
  BonusContent,
  ButtonWrapper,
  CentredWrapper,
} from 'shared/layout';
import { Text } from 'shared/typography';

export const OutOfEnergy = ({
  onNext,
  stage,
}: {
  onNext: () => void;
  stage?: number;
}) => {
  return (
    <CentredWrapper
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <DivDecoration height={'72px'} width={11} opacity={0.25} />
        <BonusContainer width='80px' height='80px'>
          <BonusContent>
            <FireIcon />
          </BonusContent>
        </BonusContainer>
        <DivDecoration isReverse height={'72px'} width={11} opacity={0.25} />
      </div>
      <BlockContent
        style={{
          marginTop: '18px',
        }}
      >
        <Text
          style={{
            fontSize: '20px',
          }}
        >
          You Rock!
        </Text>
        <Text
          style={{
            fontSize: '14px',
            marginTop: '16px',
            lineHeight: '20px',
            color: colors.secondaryColor,
            letterSpacing: 2,
            marginBottom: '20px',
            maxWidth: 277,
          }}
        >
          You've reached {stage}/10 stage.
          <br /> Get back later to play again.
          <br /> Full energy recovery takes
          <br /> 8 hours
        </Text>
        <ButtonWrapper>
          <Button
            onClick={onNext}
            color={colors.notIntensiveColor}
            borderColor={colors.primaryColor}
            decoration='basic'
            style={{ height: '56px', width: '160px', padding: '0px' }}
          >
            <Gradient color={colors.primaryColor}>
              <Text>Recover</Text>
            </Gradient>
          </Button>
        </ButtonWrapper>
      </BlockContent>
    </CentredWrapper>
  );
};
