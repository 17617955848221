import { Balance } from 'feature/game/balance';
import { H100, Header, PageContent, PageWrapper } from 'shared/layout';
import { LaunchDate } from 'widget/launchDate';
import { Menu } from 'widget/menu';
import { CharacterStats } from 'widget/characterStats';

export const Launch = () => {
  return (
    <PageWrapper>
      <Header>
        <div />
        <Balance />
        <div />
      </Header>
      <PageContent>
        <LaunchDate />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
