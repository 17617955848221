import {
  VideoFade,
  VideHeader,
  VideoWrapper,
  SkipContainer,
  VideoContainer,
  SoundMuteContainer,
  SubtitleContainer,
} from './SophiaWelcoming.styled';
import { Text } from 'shared/typography';
import { SoundIcon } from 'shared/icon/sound/Sound';
import { FC, useEffect, useRef, useState } from 'react';

interface IWelcomingVideoProps {
  showVideo: boolean;
  handleVideoEnd: () => void;
  handleClickSkip: () => void;
  isAutoPlay: boolean;
  play?: boolean;
}

export const SophiaWelcomingVideo: FC<IWelcomingVideoProps> = ({
  play,
  showVideo,
  isAutoPlay,
  handleVideoEnd,
  handleClickSkip,
}) => {
  const [isMute, setIsMute] = useState(false);
  const [videoEnd, setVideoEnd] = useState(false);
  const [activeSubtitle, setActiveSubtitle] = useState<string>(''); // Локальний стейт для активного субтитра
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleClickMute = () => {
    if (videoRef.current) {
      setIsMute(!videoRef.current.muted);
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;
      const textTracks = videoElement.textTracks;

      if (textTracks && textTracks.length > 0) {
        const track = textTracks[0]; // Отримуємо перший трек (субтитри)
        track.mode = 'hidden'; // Переконуємося, що трек активний

        const handleCueChange = () => {
          const activeCues = track.activeCues;
          if (activeCues && activeCues.length > 0) {
            // Оновлюємо стейт з текстом активного субтитра
            // @ts-ignore
            setActiveSubtitle(activeCues[0].text);
          }
        };

        track.addEventListener('cuechange', handleCueChange);

        return () => {
          track.removeEventListener('cuechange', handleCueChange);
        };
      }

      // Додаємо слухача події для завершення відео
      videoElement.addEventListener('ended', handleVideoEnd);

      return () => {
        videoElement.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, [showVideo]);

  useEffect(() => {
    if (isAutoPlay && showVideo && videoRef && videoRef.current) {
      setTimeout(() => videoRef.current?.play(), 100);
    }
  }, [showVideo, isAutoPlay, videoRef]);

  const handleEnd = () => {
    setVideoEnd(true);
    setTimeout(() => handleVideoEnd(), 500);
  };

  return (
    <VideoWrapper
      style={{
        opacity: showVideo && !videoEnd ? 1 : 0,
        zIndex: showVideo ? 100 : -1,
      }}
    >
      <VideHeader>
        <SoundMuteContainer onClick={handleClickMute}>
          <SoundIcon isMuted={isMute} />
        </SoundMuteContainer>
        <SkipContainer onClick={handleEnd}>
          <Text style={{ textAlign: 'center' }}>Skip</Text>
        </SkipContainer>
      </VideHeader>
      <VideoContainer>
        <VideoFade />
        <video
          ref={videoRef}
          style={{
            width: '100%',
            height: 'auto',
            opacity: videoEnd ? 0 : 1,
            transition: 'opacity 0.35s ease',
          }}
          playsInline
          webkit-playsinline
          onEnded={handleEnd}
        >
          <source src='/welcome/video/Soph-SINT-DONE-2.mp4' />
          <track
            src='/welcome/subtitles/Soph-SINT-subtitles.vtt'
            kind='subtitles'
            srcLang='en'
            label='English'
          />
        </video>
      </VideoContainer>
      <SubtitleContainer
        style={{
          opacity: videoEnd ? 0 : 1,
          transition: 'opacity 0.35s ease',
        }}
      >
        <Text style={{ textAlign: 'center' }}>{activeSubtitle}</Text>
      </SubtitleContainer>
    </VideoWrapper>
  );
};
