import React from 'react';
import {
  DEFAULT_CAMERA_FOV,
  DEFAULT_CAMERA_POSITION,
} from '../configs/3dModelConfigs';
import { Model } from '../model/3dModel';
import { Canvas } from '@react-three/fiber';
import { colors } from 'app/colors';
import { SceneContainer } from './Scene.styled';
import { Spinner } from 'shared/spinner';
import { use3dModelContext } from '../model/3dProvoider/3dProvoider';
import { Environment, OrbitControls, Sparkles, Stats } from '@react-three/drei';
import { RootState } from '@react-three/fiber/dist/declarations/src/core/store';

interface IProps {
  isUsingGUI?: boolean;
  width?: string;
  height?: string;
  distance?: number;
  aspect?: number;
}

export const RobotScene = React.memo(({ distance = 2.5 }: IProps) => {
  const { isModelRendered } = use3dModelContext();

  /**
   * Handle when scene will be created.
   */
  const handleSceneCreated = (state: RootState) => {
    console.log('Scene created:', state);

    return () => state.gl.dispose();
  };

  return (
    <SceneContainer id={'3d-scene'}>
      {!isModelRendered && <Spinner />}
      <Canvas
        key={new Date().toString()}
        style={{ width: '100%', height: '100%', background: 'transparent' }}
        camera={{
          position: [
            DEFAULT_CAMERA_POSITION.x,
            DEFAULT_CAMERA_POSITION.y,
            DEFAULT_CAMERA_POSITION.z,
          ],
          fov: DEFAULT_CAMERA_FOV,
        }}
        gl={{
          alpha: true,
          antialias: true,
          premultipliedAlpha: true,
        }}
        onCreated={handleSceneCreated}
      >
        {process.env.NODE_ENV === 'development' && <Stats showPanel={0} />}

        <Sparkles
          speed={0.45}
          position={[0, 0.6, 1]}
          color={colors.primaryColor3DScene}
          scale={[4, 1, 5]}
          size={0.75}
          count={80}
        />
        <Sparkles
          speed={0.45}
          position={[0, 0, 1]}
          color={colors.whiteColor}
          scale={[4, 1, 5]}
          size={0.75}
          count={80}
        />
        <Sparkles
          speed={0.45}
          position={[0, 0, 1]}
          color={colors.secondaryColor}
          scale={[4, 1, 5]}
          size={0.5}
          count={40}
        />

        <ambientLight color={colors.primaryColor3DScene} intensity={2} />

        <directionalLight
          color={colors.sceneLight3D_Direction_Second}
          position={[1, 2, 2]}
          intensity={1.5}
        />

        <directionalLight
          color={colors.sceneLight3D_Direction_Second}
          position={[-1, 3, 1]}
          intensity={1}
        />

        <Model />
        <Environment files='/model/textures/hdri/Atelier_hdr_512.hdr' />

        <OrbitControls
          rotateSpeed={0.5}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
          minDistance={distance}
          maxDistance={distance}
          enablePan={false}
          dampingFactor={0.15}
        />
      </Canvas>
    </SceneContainer>
  );
});
