// ModalProvider.tsx
import React, { useState, useEffect } from 'react';
import { ModalProps } from './modal.types';
import { Modal } from './Modal';
import { ModalContext } from './ModalContext';
import { useLockBodyScroll } from 'react-use';

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setModalProps(null);
      }, 300);
    }
  }, [isOpen]);

  useLockBodyScroll(isOpen);

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        modalProps,
        setModalProps,
      }}
    >
      {children}
      {isOpen && modalProps && (
        <Modal
          {...modalProps}
          isOpen={isOpen}
          onRequestClose={() => {
            modalProps?.onRequestClose();
            setIsOpen(false);
          }}
        />
      )}
    </ModalContext.Provider>
  );
};
