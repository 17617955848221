export const colors = {
  primaryColor: '#7f64f2',
  primaryColor3DScene: '#9700fb',
  sceneLight3D_Direction_Second: '#fde1ad',
  secondaryColor: '#d8f34f',
  tertiaryColor: '#271f4e',
  fourthColor: '#D8F34F',
  textInactiveColor: '#808084',
  inputBgColor: '#161521',
  notIntensiveColor: '#4A3C841A',
  secondaryTextColor: '#FFFFFF80',
  borderColor: '#FFFFFF1A',
  blackColor: '#02030b',
  whiteColor: '#fff',
  sceneLight3D_Direction: '#e0eeff',
  transperent: '#FFFFFF00',
  gradient: 'linear-gradient(180deg, #433581 0%, #02030B 40%)',
  progressBgColor: '#D8F34F40',
  fillBarColor: '#D8F34F',
  taskBG: '#111115',
  greenColor: '#1AFFD9',
  fillerGradient: '#262241',
  btnDoneBackground: '#262241',
  btnDoneDecoration: '#858294',
  listItemBG: '#271F4EBF',
  colorTrapezoidShape: `polygon(
    10px 0,
    calc(100% - 10px) 0,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0 calc(100% - 10px),
    0 10px
  );`,
  status: {
    success: '#00c800',
    error: '#ff0000',
    warning: '#ffa300',
  },
};
