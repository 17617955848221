import { Mesh, Clock, Object3D, AnimationMixer } from 'three';
import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { modelDefaultPos, modelDefaultScale } from '../configs/3dModelConfigs';
import { useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { use3dModelContext } from './3dProvoider/3dProvoider';
import { MovementModelController } from '../classes/movementModelController';

export const Model = React.memo(() => {
  const threeState = useThree();
  const { scene: model, animations } = useGLTF(
    '/model/draco/Sint_telegram_drako.glb'
  );
  const { scene: hologram } = useGLTF('/model/draco/Hologram_draco.glb');

  const modeRef = useRef(null);
  const clock = useRef<Clock | null>(new Clock());
  const mixer = useRef<AnimationMixer | null>(null);
  const animationFrameId = useRef<number | null>(null);

  const {
    isModelLoading,
    setIsLoading,
    isModelRendered,
    setIsModelRendered,
    setMovementControllerOfModel,
  } = use3dModelContext();

  /**
   * Function for set default scale and position for models
   */
  const makeDefaultScaleAndPos = (model: Object3D) => {
    model.scale.set(
      modelDefaultScale.x,
      modelDefaultScale.y,
      modelDefaultScale.z
    );

    model.position.set(modelDefaultPos.x, modelDefaultPos.y, modelDefaultPos.z);
  };

  /**
   * Function for manual dispose model materials, textures geometries
   */
  const customDisposeModel = (model: Object3D) => {
    model.traverse((obj) => {
      if (obj instanceof Mesh) {
        if (obj.geometry) obj.geometry.dispose();
        if (Array.isArray(obj.material)) {
          obj.material.forEach((mat) => mat.dispose());
        } else if (obj.material) {
          const mat = obj.material;
          // TODO add remove from memory all textures which material can had

          if (mat.map) mat.map.dispose();
          if (mat.lightMap) mat.lightMap.dispose();
          if (mat.bumpMap) mat.bumpMap.dispose();
          if (mat.normalMap) mat.normalMap.dispose();
          if (mat.specularMap) mat.specularMap.dispose();
          if (mat.envMap) mat.envMap.dispose();
          if (mat.alphaMap) mat.alphaMap.dispose();
          if (mat.aoMap) mat.aoMap.dispose();
          if (mat.gradientMap) mat.gradientMap.dispose();
          if (mat.displacementMap) mat.displacementMap.dispose();
          if (mat.roughnessMap) mat.roughnessMap.dispose();
          if (mat.metalnessMap) mat.metalnessMap.dispose();
          if (mat.emissiveMap) mat.emissiveMap.dispose();

          mat.dispose();
        }
      }
    });
  };

  /**
   * Run animation when model is ready >>>
   */
  useLayoutEffect(() => {
    if (!model) return setIsModelRendered(false);

    if (isModelLoading) {
      setIsModelRendered(false);
      console.log('Model is loading, skipping animation setup.');
      return;
    }

    mixer.current = new AnimationMixer(model);

    // Запуск усіх анімацій одночасно
    if (animations.length > 0) {
      animations.forEach((animation) => {
        const action = mixer.current?.clipAction(animation);
        if (action) {
          action.reset();
          action.play();
        } else {
          console.warn(`Animation "${animation.name}" not found in the mixer.`);
        }
      });
    } else {
      console.warn('No animations found in the loaded model.');
    }

    return () => {
      if (mixer.current) {
        mixer.current.stopAllAction();
        mixer.current.uncacheRoot(model);
        mixer.current = null;
      }
    };
  }, [model, isModelLoading, animations]);

  /**
   * Animation process >>>
   */
  useLayoutEffect(() => {
    const animate = () => {
      animationFrameId.current = requestAnimationFrame(animate);

      if (mixer.current && clock.current) {
        if (!isModelRendered) {
          setIsModelRendered(true);
        }
        mixer.current.update(clock.current.getDelta());
      }
    };

    animate();

    return () => {
      // Зупиняємо анімацію при демонтажі
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
        animationFrameId.current = null;
        clock.current = null;
      }
    };
  }, [model]);

  /**
   * Mount and Unmount model on scene >>>
   */
  useEffect(() => {
    if (model && hologram) {
      makeDefaultScaleAndPos(model);
      makeDefaultScaleAndPos(hologram);

      setMovementControllerOfModel(
        new MovementModelController({
          sintModel: model,
          hologramModel: hologram,
          threeState: threeState,
        })
      );
    }

    return () => {
      customDisposeModel(model);
      customDisposeModel(hologram);
    };
  }, [model, hologram]);

  /**
   * Set model loading when model is rendered on scene >>>
   */
  useEffect(() => {
    if (modeRef && modeRef.current) {
      setIsLoading(false);
    }
  }, [modeRef]);

  return (
    <group>
      {model ? <primitive ref={modeRef} object={model} /> : null}
      {hologram ? <primitive object={hologram} /> : null}
    </group>
  );
});
