import { BatteryIcon, IntelligenceIcon, PersonalityIcon } from 'shared/icon';
import { Content, Filter, Wrapper } from './UpgradeProgress.styled';
import { Button } from 'shared/button';
import { ProgressBar } from 'shared/progress';
import { Text } from 'shared/typography';
import { colors } from 'app/colors';

const UPGRADES = [
  {
    title: 'Personality',
    icon: <PersonalityIcon color='#FFFFFF40' />,
    progress: 10,
  },
  {
    title: 'Intelligence',
    icon: <IntelligenceIcon color='#FFFFFF40' />,
    progress: 30,
  },
  { title: 'Battery ', icon: <BatteryIcon color='#FFFFFF40' />, progress: 40 },
];

export const UpgradeProgress = () => {
  return (
    <>
      {UPGRADES.map(({ title, icon, progress }) => {
        return (
          <Wrapper>
            <Button
              borderSize={0}
              size='xs'
              decoration='basic'
              borderColor={'#FFFFFF40'}
            >
              {icon}
            </Button>
            <Content>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text style={{ color: '#FFFFFF40', marginBottom: 8 }}>
                  {title}
                </Text>
                <Text style={{ marginBottom: 8, fontSize: 16 }}>
                  In progress
                </Text>
              </div>
              <ProgressBar color='#FFFFFF40' current={progress} target={100} />
            </Content>
          </Wrapper>
        );
      })}
    </>
  );
};
