import { QRContent } from 'feature/qrScreen';
import { Background } from 'shared/layout';

export const QRScreen = () => {
  return (
    <Background>
      <QRContent />
    </Background>
  );
};
