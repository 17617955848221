import { useToast } from 'shared/toast';
import { useSelector } from 'react-redux';
import { ReactNode, useEffect } from 'react';
import { selectFetchDailyTasks, Item } from 'entities/tasks';

export const DailyProvider = ({ children }: { children: ReactNode }) => {
  const dailyTasks = useSelector(selectFetchDailyTasks);
  const { showToast } = useToast();

  useEffect(() => {
    if (dailyTasks && dailyTasks.data && dailyTasks.data.length) {
      const storedTasks = (() => {
        try {
          const data = localStorage.getItem('dailyTasks');
          return Array.isArray(JSON.parse(data || '[]'))
            ? JSON.parse(data || '[]')
            : [];
        } catch {
          return [];
        }
      })();

      const storedTasksMap = new Map<string, Item>(
        storedTasks.map((task: Item) => [task.id, task])
      );

      dailyTasks.data.forEach((task) => {
        const storedTask = storedTasksMap.get(task.id);
        if (task.completed && (!storedTask || !storedTask.completed)) {
          showToast(`You have completed "${task.name}" task!`, 'success', {
            autoClose: 2000,
          });
        }
      });

      const arraysAreEqual =
        storedTasks.length === dailyTasks.data.length &&
        storedTasks.every((storedTask: Item) =>
          dailyTasks.data.some(
            (task: Item) =>
              task.id === storedTask.id &&
              task.name === storedTask.name &&
              task.completed === storedTask.completed
          )
        );

      if (!arraysAreEqual) {
        localStorage.setItem('dailyTasks', JSON.stringify(dailyTasks.data));
      }
    }
  }, [dailyTasks]);

  return <>{children}</>;
};
