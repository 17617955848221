import { Text } from 'shared/typography';
import styled, { keyframes } from 'styled-components';

// Создаем анимацию покачивания

export const StyledText = styled(Text)`
  margin: 16px 0;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;
