import {
  CentredWrapper,
  Header,
  PageContent,
  PageWrapper,
} from 'shared/layout';
import { WaitListSuccessContent } from 'widget/waitList';

export const WaitListSuccess = () => {
  return (
    <PageWrapper>
      <CentredWrapper>
        <WaitListSuccessContent />
      </CentredWrapper>
    </PageWrapper>
  );
};
