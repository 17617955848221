import { SettingsState } from './types';
import { RootState } from 'app/store/rootStore';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Initial state for settingsSlice
const initialState: SettingsState = {
  is3dSceneMounting: false,
  isShowMenu: true,
  isShowStars: false,
};

// Create settingsSlice
export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIs3dSceneMounting: (state, action: PayloadAction<boolean>) => {
      state.is3dSceneMounting = action.payload;
    },

    setIsShowMenu: (state, action: PayloadAction<boolean>) => {
      state.isShowMenu = action.payload;
    },
    setShowStars: (state, action: PayloadAction<boolean>) => {
      state.isShowStars = action.payload;
    },
    setCustomField: (
      state,
      action: PayloadAction<{
        field: keyof SettingsState;
        value: SettingsState[keyof SettingsState];
      }>
    ) => {
      state[action.payload.field] = action.payload.value;
    },
  },
});

// Export actions
export const {
  setIs3dSceneMounting,
  setIsShowMenu,
  setCustomField,
  setShowStars,
} = settingsSlice.actions;

// Export selectors
export const selectorIs3dSceneMounting = (state: RootState) =>
  state.settings.is3dSceneMounting;

// Export selectors
export const selectMenuVisability = (state: RootState) =>
  state.settings.isShowMenu;

export const selectStarsVisability = (state: RootState) =>
  state.settings.isShowStars;

// Export reducer
export const settingsReducer = settingsSlice.reducer;
