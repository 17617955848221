import { RobotScene } from '../../ui/Scene';
import React, { createContext, Dispatch, useContext, useState } from 'react';
import { MovementModelController } from '../../classes/movementModelController';
import { useDispatch } from 'react-redux';
import { setIs3dSceneMounting } from 'entities/settings';

interface ModelContextType {
  isModelRendered: boolean;
  isModelLoading: boolean;
  activeAnimation: string;
  isSceneMounting: boolean;
  setRotationX: (isRotation: boolean) => void;
  isRotationX: boolean;
  movementControllerOfModel: MovementModelController | undefined;
  setMovementControllerOfModel: Dispatch<MovementModelController>;

  setIsLoading: (value: boolean) => void;
  setIsModelRendered: (value: boolean) => void;
  mount3dScene: (targetID: string) => void;
  unmount3dScene: () => void;
  setActiveAnimation: (name: string) => void;
  setModelTextures: (textures: {
    baseColor?: string;
    emissive?: string;
    metallic?: string;
    normal?: string;
    roughness?: string;
  }) => void;
  // Next code will be using in future.
  // model: THREE.Group | null;
  // animations: THREE.AnimationClip[];
}

// Создаем контекст для 3D модели
const ModelContext = createContext<ModelContextType | undefined>(undefined);

// Провайдер для 3D модели
export const Model3dProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSceneMounting, setIsSceneMounting] = useState(false);
  const dispatch = useDispatch();
  const [isModelRendered, setIsModelRendered] = useState(false);
  const [isRotationX, setRotationX] = useState(true);

  const [movementControllerOfModel, setMovementControllerOfModel] =
    useState<MovementModelController>();

  const [activeAnimation, setActiveAnimation] = useState(
    'Armature|Take 001|BaseLayer'
  );
  const [textures, setTextures] = useState({
    baseColor: '/model/textures/Sohp2_Soph2_0_Body_BaseColor.png',
    metallic:
      '/model/textures/Sohp2_Soph2_0_Body_Metallic_png-Sohp2_Soph2_0_Body_Roughness_png.png',
  });

  // Функция для обновления текстур модели
  const setModelTextures = (newTextures: {
    baseColor?: string;
    emissive?: string;
    metallic?: string;
    normal?: string;
    roughness?: string;
  }) => {
    setTextures((prevTextures) => ({
      ...prevTextures,
      ...newTextures,
    }));
  };

  const mount3dScene = (targetID: string) => {
    dispatch(setIs3dSceneMounting(true));

    const targetElement = document.getElementById(targetID);
    const canvasElement = document.getElementById('3d-scene');

    if (targetElement && canvasElement) {
      canvasElement.style.width = targetElement.style.width;
      canvasElement.style.height = targetElement.style.height;

      setTimeout(() => {
        targetElement.appendChild(canvasElement);
        dispatch(setIs3dSceneMounting(false));
      }, 150);
    } else {
      dispatch(setIs3dSceneMounting(false));
    }
  };

  const unmount3dScene = () => {
    dispatch(setIs3dSceneMounting(true));
    const canvasContainerElement = document.getElementById(
      'tempCanvasContainer'
    );
    const canvasElement = document.getElementById('3d-scene');

    if (canvasContainerElement && canvasElement) {
      movementControllerOfModel?.moveModelsToDefaultPos();
      canvasContainerElement.appendChild(canvasElement);
    }
    dispatch(setIs3dSceneMounting(false));
  };

  return (
    <ModelContext.Provider
      value={{
        isModelRendered,
        isRotationX,
        setRotationX: (isRotation: boolean) => setRotationX(isRotation),
        isSceneMounting,
        setIsModelRendered,
        isModelLoading: isLoading,
        setIsLoading,
        activeAnimation,
        setActiveAnimation,
        setModelTextures,
        mount3dScene,
        unmount3dScene,
        movementControllerOfModel,
        setMovementControllerOfModel,
      }}
    >
      <div
        id={'tempCanvasContainer'}
        style={{ position: 'fixed', top: 0, left: 0, zIndex: -1, opacity: 0 }}
      >
        <RobotScene />
      </div>
      {children}
    </ModelContext.Provider>
  );
};

export const use3dModelContext = () => {
  const context = useContext(ModelContext);
  if (!context) {
    throw new Error('use3dModelContext must be used within a Model3dProvider');
  }
  return context;
};
