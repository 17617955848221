import { useState } from 'react';
import { useInterval } from 'react-use';
import { Counter } from 'shared/counter';
import {
  BalanceRow,
  CircleBg,
  Content,
  Title,
  Wrapper,
} from './Balance.styled';
import { CoinIcon, LotusIcon } from 'shared/icon';
import { colors } from 'app/colors';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';

import { useAppSelector } from 'app/store/rootStore';
import { selectBalance } from 'entities/user';
import { formatNumber } from 'shared/utils';
import { LogoIcon } from 'shared/icon/LogoIcon';

export const Balance = () => {
  const walletBalance = useAppSelector(selectBalance);

  return (
    <Wrapper>
      <Title> your balance</Title>
      <Content>
        <DivDecoration height={'100%'} />
        <BalanceRow>
          <Counter
            startValueOnce={true}
            startValue={walletBalance}
            value={walletBalance}
          />
          <LogoIcon size={24} />
        </BalanceRow>
        <DivDecoration isReverse height={'100%'} />
      </Content>
    </Wrapper>
  );
};
