import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { Text } from 'shared/typography';
import styled, { keyframes, css } from 'styled-components';

// Определение анимации
const disappear = keyframes`
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(0);
    }
`;
const appear = keyframes`
    0% {
        transform: scale(0);
    }
    30% {
        transform: scale(0.6);
    }
    100% {
        transform: scale(1);
    }
`;

const streak = keyframes`
    0% {
        transform: scale(0);
    }
    20%{
        transform: scale(0.6);
    }
    50% {
        transform: scale(1);
    }
    75%{
        transform: scale(0.4);
    }
    100% {
        transform: scale(0);
    }
`;

const flame = keyframes`
  from {
    r: 1px;
    opacity: 0.8;
  }
  to {
    r: 28px;
    opacity: 0;
  }
`;

export const Value = styled.div`
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
  left: 48%;
  transform: translateX(-50%);
  z-index: 100;
`;
export const StyledSVG = styled.svg``;

export const StreakWrapper = styled.div`
  animation: ${appear} 0.4s forwards;
  height: 100px;
  width: 100px;
  position: relative;
`;

export const Circle = styled.circle`
  fill: #fff;
  animation: ${flame} 2s ease-out infinite;

  &:nth-child(2) {
    animation-delay: -0.5s;
  }

  &:nth-child(3) {
    animation-delay: -1s;
  }

  &:nth-child(4) {
    animation-delay: -1.5s;
  }
`;

export const GameButton = styled(Button)<{ isAnimating: boolean }>`
  ${(props) => css`
    pointer-events: ${props.isAnimating ? 'none' : 'auto'};
    touch-action: ${props.isAnimating ? 'none' : 'auto'};
    animation: ${props.isAnimating ? disappear : appear} 0.4s forwards;
  `}
`;

export const GameWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  & * {
    z-index: 2;
  }
`;

export const Streak = styled(Text)`
  color: ${colors.whiteColor};
  font-size: 30px;
  padding: 5px 0;
  font-weight: 700;
  text-transform: lowercase;
  text-align: center;
  text-decoration-skip-ink: none;
  letter-spacing: -1px;
  position: relative;
  animation: ${appear} 0.4s forwards;
  text-shadow: ${colors.whiteColor + '99'} 0px 0px 40px;
`;

export const SlideWrapper = styled.div``;

export const ExitWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 23px;
  height: 27px;
  width: 27px;
  button {
    height: 100%;
    width: 100%;
  }
`;

export const RobotWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  height: 100%;
`;

export const Scene3dContainerGamePage = styled.div`
  width: 100%;
  touch-action: none;

  height: 100%;
`;

export const BubbleArea = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  min-height: 150px;
  width: 100%;
  z-index: 3;
  color: ${colors.whiteColor};
`;

export const InfoWrapper = styled.div`
  padding: 20px 0;
`;

export const StatsWrapper = styled.div`
  position: absolute;
  bottom: 60px;
`;
