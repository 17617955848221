import { useState, useEffect, useLayoutEffect } from 'react';
import { TelegramUser } from './types';
import { colors } from 'app/colors';
import { isMobileOnly } from 'react-device-detect';

// Хук для авторизации пользователя в Telegram Web App
export const useTelegram = () => {
  const [user, setUser] = useState(null);
  const [initData, setInitData] = useState(null);
  const [startParams, setStartParams] = useState(undefined);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (typeof window.Telegram !== 'undefined') {
      const tg = window.Telegram.WebApp;

      const userData = tg.initDataUnsafe;

      if (Object.keys(userData).length !== 0) {
        tg.expand();

        tg.setBackgroundColor(colors.blackColor);
        tg.disableVerticalSwipes();
        // Если пользовательские данные доступны, обновляем состояние
        setUser(userData);
        setInitData(tg.initData);
        setStartParams(userData.start_param);

        tg.ready();
        setReady(true);
        try {
          if (isMobileOnly) {
            tg?.requestFullscreen();
            tg?.lockOrientation();
          }
        } catch (error) {}
      } else {
        // Если данные пользователя недоступны, устанавливаем isReady в false
        console.warn('User data not available');
        setReady(false);
      }
    } else {
      console.error('Telegram Web App is not available');
      setReady(false);
    }

    // Функция очистки (если нужно)
    return () => {
      setUser(null);
      setInitData(null);
      setStartParams(undefined);
      setReady(false);
    };
  }, []);

  return { user, initData, isReady, startParams };
};
