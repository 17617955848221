import styled from 'styled-components';

export const AnimatedBorderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 41px;
  margin: 16px auto;
  border-radius: 56px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: visible;
  }

  .children-container {
    position: relative;
    z-index: 1;
    border-radius: 56px;
  }
`;
