import React from 'react';
import { useInterval } from 'react-use';

import { useAppDispatch, useAppSelector } from 'app/store/rootStore';
import { selectEnergy, updateEnergy } from 'entities/user';

export const EnergyProvider = ({ children }: { children: React.ReactNode }) => {
  const energy = useAppSelector(selectEnergy);
  const dispatch = useAppDispatch();

  // Автообновление энергии каждые 60 секунды
  useInterval(() => {
    dispatch(updateEnergy(Math.min(energy + 1, 500)));
  }, 60000);
  return <>{children}</>;
};
