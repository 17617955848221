import { useState, useEffect } from 'react';
import { colors } from 'app/colors';
import { ReferralsCount } from 'entities/user';
import { Button } from 'shared/button';
import {
  BlockContent,
  BlockWrapper,
  BonusContainer,
  BonusContent,
  ButtonWrapper,
  CentredWrapper,
  CopyContainer,
  InputWrapper,
  TextContainer,
} from 'shared/layout';
import { Text } from 'shared/typography';

import { Gradient } from 'shared/gradient';
import { Input } from 'shared/input';
import { Copy } from 'shared/copy';
import {
  selectUserId,
  selectRefInfo,
  selectRefCount,
} from 'entities/user/model/slice';
import { useAppSelector } from 'app/store/rootStore';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import {
  generateReferralLink,
  generateShareURL,
  getVisibleReferralLink,
} from 'shared/utils/linkHelper';
import { RewardsIcon } from 'shared/icon';
import { PeopleIcon } from 'shared/icon';
import { useGenRefLinkMutation } from 'entities/user/model/api';
import { Loader } from 'shared/loader';

export const ReferralContent = () => {
  const referralId = useAppSelector(selectUserId);
  const refInfo = useAppSelector(selectRefInfo);
  const refCount = useAppSelector(selectRefCount);

  const [referralLink, setReferralLink] = useState<string>('');

  useEffect(() => {
    if (refInfo?.referralLink) {
      const generatedLink = generateReferralLink(refInfo.referralLink);
      setReferralLink(generatedLink);
    } else {
      const generatedLink = generateReferralLink(referralId);
      setReferralLink(generatedLink);
    }
  }, [refInfo?.referralLink, referralId]);

  const handleShareClick = () => {
    const shareURL = generateShareURL(
      referralLink || '',
      'Join Game via the Referral Link and Get Rewards!'
    );
    window.open(shareURL, '_blank');
  };

  return (
    <CentredWrapper
      style={{
        width: '100%',
      }}
    >
      <BlockWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <DivDecoration height={'100px'} width={11} opacity={0.25} />
          <BonusContainer>
            <BonusContent>
              <Text
                style={{
                  color: colors.secondaryColor,
                  fontSize: '12px',
                  lineHeight: '0.6',
                }}
              >
                Your Bonus
              </Text>
              <Text
                style={{
                  color: colors.secondaryColor,
                  fontWeight: 600,
                  fontSize: '80px',
                  marginTop: '9px',
                  lineHeight: '0.6',
                }}
              >
                10<span style={{ fontSize: '16px' }}>%</span>
              </Text>
            </BonusContent>
          </BonusContainer>
          <DivDecoration isReverse height={'100px'} width={11} opacity={0.25} />
        </div>
        <BlockContent
          style={{
            marginTop: '12px',
            marginBottom: 16,
          }}
        >
          <Text
            style={{
              color: colors.secondaryColor,
              fontSize: 12,
              letterSpacing: 4,
            }}
          >
            Referral Count
          </Text>
          <TextContainer
            style={{
              marginTop: '5px',
              lineHeight: '24px',
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            {refInfo?.maxNumberOfAccounts
              ? `${refInfo?.invitedCount}/${refInfo?.maxNumberOfAccounts}`
              : refCount}
          </TextContainer>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        <BlockContent>
          <Text
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              letterSpacing: '4px',
              gap: '10px',
            }}
          >
            <PeopleIcon />
            Invite
          </Text>
          <TextContainer>
            <Text
              style={{
                fontSize: '12px',
                marginTop: '2px',
                letterSpacing: '1.28px',
              }}
            >
              you can invite up to 5 friends via referral link
            </Text>
          </TextContainer>
        </BlockContent>
        <BlockContent
          style={{
            marginTop: '18px',
          }}
        >
          <Text
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              letterSpacing: '4px',
              gap: '10px',
            }}
          >
            <RewardsIcon />
            Get rewards
          </Text>
          <TextContainer>
            <Text
              style={{
                fontSize: '12px',
                marginTop: '2px',
                letterSpacing: '1.28px',
              }}
            >
              Receive 10% of Your Friends staking
            </Text>
          </TextContainer>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        <InputWrapper>
          <Input
            disabled
            bgColor={colors.inputBgColor}
            color={colors.whiteColor}
            placeholderColor={colors.whiteColor}
            type='text'
            value={getVisibleReferralLink(referralLink)}
            onChange={() => {}}
            placeholder={getVisibleReferralLink(referralLink)}
          />
          <CopyContainer>
            <Copy textToCopy={referralLink} alertText='Link copied' />
          </CopyContainer>
        </InputWrapper>
        <ButtonWrapper>
          <Button
            color={colors.notIntensiveColor}
            borderColor={colors.primaryColor}
            decoration='basic'
            style={{ height: '56px', width: '160px' }}
            onClick={handleShareClick}
          >
            <Gradient color={colors.primaryColor}>
              <Text>Invite</Text>
            </Gradient>
          </Button>
        </ButtonWrapper>
      </BlockWrapper>
    </CentredWrapper>
  );
};
