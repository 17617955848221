import React from 'react';

import { LayoutProps } from './layout.types';

import { Section, Span, StyledLayout, Background } from './Layout.styled';
import { useAppSelector } from 'app/store/rootStore';
import { selectStarsVisability } from 'entities/settings';

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const isStars = useAppSelector(selectStarsVisability);

  return (
    <Background>
      {isStars && (
        <Section>
          <Span></Span>
          <Span></Span>
          <Span></Span>
          <Span></Span>
          <Span></Span>
          <Span></Span>
          <Span></Span>
          <Span></Span>
          <Span></Span>
          <Span></Span>
        </Section>
      )}
      <StyledLayout>{children}</StyledLayout>
    </Background>
  );
};
