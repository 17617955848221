import { colors } from 'app/colors';

import { Button } from 'shared/button';

import { CoinIcon } from 'shared/icon';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import { Text } from 'shared/typography';
import { CheckMark, Wrapper } from './FakeMint.styled';

import { Gradient } from 'shared/gradient';
import { BonusContainer } from 'shared/layout';

export const Mint = ({ onNext }: { onNext: () => void }) => {
  return (
    <Wrapper>
      <CheckMark>
        <DivDecoration width={10} height={'72px'} />
        <BonusContainer width='80px' height='80px'>
          <CoinIcon size={50} color={colors.secondaryColor} />
        </BonusContainer>
        <DivDecoration width={10} isReverse height={'72px'} />
      </CheckMark>
      <div style={{ marginTop: 20, marginBottom: 14, textAlign: 'center' }}>
        <Text style={{ fontSize: 20 }}>Ready to mint?</Text>

        <Text
          style={{ color: colors.fourthColor, letterSpacing: 2, marginTop: 10 }}
        >
          Get back to receive your
          <br /> character in 3 days.
          <br /> Meanwhile, complete tasks,
          <br /> invite friends, keep earning <br />
          points
        </Text>
      </div>

      <Button
        onClick={onNext}
        decoration='basic'
        borderColor={colors.primaryColor}
        size='l'
      >
        <Gradient color={colors.primaryColor}>
          <Text>MINT SINT</Text>
        </Gradient>
      </Button>
    </Wrapper>
  );
};
