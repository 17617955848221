import {
  CentredWrapper,
  Header,
  PageContent,
  PageWrapper,
} from 'shared/layout';
import { WaitListContent } from 'widget/waitList';

export const WaitList = () => {
  return (
    <PageWrapper>
      <CentredWrapper style={{ paddingBottom: 40 }}>
        <WaitListContent />
      </CentredWrapper>
    </PageWrapper>
  );
};
