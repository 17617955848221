import styled from 'styled-components';
import { colors } from 'app/colors';

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  position: absolute;
  top: 20%;
  left: 0;
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2px;
  color: ${colors.whiteColor};
`;

export const StatIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatDots = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;
