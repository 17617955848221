import { colors } from 'app/colors';
import { Text } from 'shared/typography';
import styled from 'styled-components';

export const Wrapeer = styled.div``;

export const ButtonWrapper = styled.div`
  margin: 20px 0 16px;
  height: 56px;
  button {
    height: 100%;
    width: 100%;
    padding: 0 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.21em;
    text-transform: uppercase;
  }
  a {
    text-decoration: none;
    height: 100%;
    width: 100%;
  }
`;

export const StatsWrapper = styled.div`
  display: flex;
  margin: 20px 0 0;
  align-items: center;
  gap: 12px;
`;
export const StatWrapper = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  height: 43px;
  color: ${colors.secondaryTextColor};

  &:nth-child(2) {
    border-right: 1px solid ${colors.borderColor};
    border-left: 1px solid ${colors.borderColor};
    width: fit-content;
    padding: 0 12px;
  }
  &:first-child {
  }
  &:last-child {
    border-right: none;
  }
`;
export const Stat = styled(Text)`
  font-size: 20px;
  line-height: 22px;
`;
