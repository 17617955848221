import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  button {
    text-transform: uppercase;
  }
`;

export const CheckMark = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
`;

export const TextBlock = styled.div`
  margin: 24px 0;
`;
