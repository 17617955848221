import styled from 'styled-components';

export const LogoContainer = styled.div`
  margin-bottom: 52px;
`;

export const TextContainer = styled.div`
  padding-bottom: 200px;
`;

export const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 80px 0 50px 0;

  transition: opacity 0.35s ease 0.2s;
`;

export const VideoContainer = styled.div`
  position: relative;
  padding: 10px;
`;

export const VideHeader = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const VideoFade = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 110;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 15%,
    rgba(0, 0, 0, 0) 40%
  );
  pointer-events: 'none';
`;

export const SubtitleContainer = styled.div`
  width: 100%;
  height: 120px;
  z-index: 100;
  color: white;
  font-size: 18px;
  padding: 5px 55px;
`;

export const SkipContainer = styled.div`
  padding: 5px;
`;

export const SoundMuteContainer = styled.div`
  padding: 5px;
`;
