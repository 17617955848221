import { Text } from 'shared/typography';
import { colors } from 'app/colors';
import {
  StatsContainer,
  StatItem,
  StatIcon,
  StatDots,
} from './StatsItem.styled';
import { CharacterStats } from 'widget/characterStats/CharacterStats.types';

const textStyle = {
  opacity: 0.5,
  fontWeight: 400,
  fontSize: 10,
  lineHeight: '160%',
  letterSpacing: '1.6px',
  textTransform: 'uppercase',
} as const;

interface StatsItemProps {
  stats: CharacterStats[];
}

export const StatsItem = ({ stats }: StatsItemProps) => {
  return (
    <StatsContainer>
      {stats.map(({ id, name, icon: Icon, data }) => (
        <StatItem key={id}>
          <StatIcon>
            <Icon size={20} color={colors.whiteColor} />
          </StatIcon>
          <Text style={textStyle}>{name}</Text>
          <StatDots>{data}</StatDots>
        </StatItem>
      ))}
    </StatsContainer>
  );
};
