export const PARAMETERS = {
  COLORS: [
    '#7F64F2',
    '#D8F34F',
    '#1AE8FF',
    '#F25959',
    '#2067E8',
    '#FF8C00',
    '#1AFF63',
    // '#FFFFFF',
  ] as const,
  VALUES: [0, 1] as const,
};
export const MAX_UNIQUE_COMBINATIONS =
  PARAMETERS.COLORS.length * PARAMETERS.VALUES.length;
