import { SpinnerCircle, SpinnerGroup, SpinnerWrapper } from './Spinner.styled';

export const Spinner = () => {
  return (
    <SpinnerWrapper>
      <SpinnerGroup>
        <SpinnerCircle
          size={95}
          borderPx={2}
          borderTopPx={2}
          color='#fff'
          duration='5s'
          direction='normal'
        />
        <SpinnerCircle
          size={105}
          borderPx={2}
          borderTopPx={2}
          color='#e0e0e0'
          duration='4s'
          direction='reverse'
        />
        <SpinnerCircle
          size={115}
          borderPx={2}
          borderTopPx={2}
          color='#cccccc'
          duration='3s'
          direction='normal'
        />
      </SpinnerGroup>
    </SpinnerWrapper>
  );
};
