import { ROUTES } from 'app';
import { colors } from 'app/colors';
import { useAppSelector } from 'app/store/rootStore';
import { selectIsWaitlisted, selectUser } from 'entities/user';
import { useAuth } from 'feature/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { CoinIcon, PeopleIcon, RewardsIcon } from 'shared/icon';

import { Input } from 'shared/input';
import {
  BlockContent,
  BlockWrapper,
  BounceKeyFrame,
  CentredWrapper,
  InputWrapper,
  TextContainer,
} from 'shared/layout';
import { Logo } from 'shared/logo';
import { useToast } from 'shared/toast';
import { Text } from 'shared/typography';
import { ButtonWrapper } from 'widget/profile/Profile.styled';

export const WaitListContent = () => {
  const [email, setEmail] = useState('');
  const { joinWaitList } = useAuth();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const isWaitlisted = useAppSelector(selectIsWaitlisted);

  const { email: userEmail } = useAppSelector(selectUser);
  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const onEnter = () => {
    if (!validateEmail(email)) {
      showToast('Invalid email', 'error');
      return;
    }
    joinWaitList(email);
  };

  useEffect(() => {
    if (isWaitlisted || userEmail) {
      showToast('Successfully joined the waitlist!', 'success');
      navigate(ROUTES.WAITLISTSUCCESS);
    }
  }, [isWaitlisted, userEmail]);

  return (
    <CentredWrapper
      style={{
        width: '100%',
      }}
    >
      <BlockWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '12px',

            marginBottom: 25,
          }}
        >
          <BounceKeyFrame>
            <Logo gradient />
          </BounceKeyFrame>
        </div>
        <BlockContent
          style={{
            marginTop: '12px',
          }}
        >
          <Text
            style={{
              color: colors.secondaryColor,
            }}
          ></Text>
          <TextContainer
            style={{
              marginTop: '5px',
            }}
          ></TextContainer>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        <BlockContent>
          <Text
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '24px',
              letterSpacing: '4px',
              lineHeight: '28px',
              gap: '10px',
            }}
          >
            SINT is running
            <br />
            in private mode
          </Text>
        </BlockContent>
        <BlockContent
          style={{
            marginTop: '12px',
          }}
        >
          <Text
            style={{
              fontSize: '12px',
              marginTop: '2px',
              lineHeight: '20px',
              letterSpacing: 4,
              marginBottom: '20px',
            }}
          >
            Find a referral link online
            <br /> or drop your email to be among
            <br /> the first on public launch
          </Text>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        <InputWrapper>
          <Input
            bgColor={colors.inputBgColor}
            color={colors.whiteColor}
            placeholderColor={colors.whiteColor}
            value={email}
            onChange={setEmail}
            onEnter={onEnter}
            placeholder='enter your email'
            type='text'
          />
        </InputWrapper>
        <ButtonWrapper>
          <Button
            onClick={onEnter}
            color={colors.notIntensiveColor}
            borderColor={colors.primaryColor}
            decoration='basic'
            style={{ height: '56px', width: '160px', padding: '0px' }}
          >
            <Gradient color={colors.primaryColor}>
              <Text>Notify me</Text>
            </Gradient>
          </Button>
        </ButtonWrapper>
      </BlockWrapper>
    </CentredWrapper>
  );
};
