import { IconProps } from '../icon.types';

export const QRcode = ({ width = 176, height = 176, ...props }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 176 176'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M100.138 0H121.379C124.731 0 127.448 2.71719 127.448 6.06896V15.1724C127.448 16.8475 126.089 18.2069 124.414 18.2069C122.739 18.2069 121.379 16.8475 121.379 15.1724V12.1379C118.027 12.1379 115.31 9.42078 115.31 6.06896H100.138C98.4612 6.06896 97.1034 4.70954 97.1034 3.03448C97.1034 1.35946 98.4612 0 100.138 0Z'
      fill='url(#paint0_linear_8745_29999)'
    />
    <path
      d='M66.7586 3.03452C66.7586 1.35864 65.4001 4.23337e-05 63.7241 4.23337e-05C62.0483 4.23337e-05 60.6897 1.35864 60.6897 3.03452C60.6897 4.71049 62.0483 6.06901 63.7241 6.06901C65.4001 6.06901 66.7586 4.71049 66.7586 3.03452Z'
      fill='url(#paint1_linear_8745_29999)'
    />
    <path
      d='M57.6552 6.06896H51.5862C49.9094 6.06896 48.5517 7.42843 48.5517 9.10345C48.5517 10.7785 49.9094 12.1379 51.5862 12.1379H57.6552C59.3302 12.1379 60.6897 10.7785 60.6897 9.10345C60.6897 7.42843 59.3302 6.06896 57.6552 6.06896Z'
      fill='url(#paint2_linear_8745_29999)'
    />
    <path
      d='M97.1034 9.10349C97.1034 7.42761 95.7449 6.06901 94.069 6.06901C92.3931 6.06901 91.0345 7.42761 91.0345 9.10349C91.0345 10.7795 92.3931 12.138 94.069 12.138C95.7449 12.138 97.1034 10.7795 97.1034 9.10349Z'
      fill='url(#paint3_linear_8745_29999)'
    />
    <path
      d='M109.241 12.1379H106.207C104.53 12.1379 103.172 13.4974 103.172 15.1724C103.172 16.8475 104.53 18.2069 106.207 18.2069H109.241V24.2759C105.89 24.2759 103.172 26.993 103.172 30.3448C99.8206 30.3448 97.1034 33.062 97.1034 36.4138V48.5517H94.069C92.3922 48.5517 91.0345 49.9112 91.0345 51.5862C91.0345 53.2612 92.3922 54.6207 94.069 54.6207H97.1034C100.455 54.6207 103.172 51.9035 103.172 48.5517V36.4138C106.524 36.4138 109.241 33.6966 109.241 30.3448C112.593 30.3448 115.31 27.6277 115.31 24.2759V18.2069C115.31 14.8551 112.593 12.1379 109.241 12.1379Z'
      fill='url(#paint4_linear_8745_29999)'
    />
    <path
      d='M103.172 21.2414C103.172 19.5655 101.814 18.2069 100.138 18.2069C98.462 18.2069 97.1034 19.5655 97.1034 21.2414C97.1034 22.9174 98.462 24.2759 100.138 24.2759C101.814 24.2759 103.172 22.9174 103.172 21.2414Z'
      fill='url(#paint5_linear_8745_29999)'
    />
    <path
      d='M121.379 30.3448H118.345C116.668 30.3448 115.31 31.7043 115.31 33.3793C115.31 35.0544 116.668 36.4138 118.345 36.4138H121.379V42.4828H118.345C116.668 42.4828 115.31 43.8422 115.31 45.5172C115.31 47.1923 116.668 48.5517 118.345 48.5517H121.379C124.731 48.5517 127.448 45.8346 127.448 42.4828V36.4138C127.448 33.062 124.731 30.3448 121.379 30.3448Z'
      fill='url(#paint6_linear_8745_29999)'
    />
    <path
      d='M72.8276 39.4483C72.8276 37.7732 74.187 36.4138 75.8621 36.4138C77.5371 36.4138 78.8966 37.7732 78.8966 39.4483V45.5172C78.8966 47.1923 77.5371 48.5517 75.8621 48.5517C74.187 48.5517 72.8276 47.1923 72.8276 45.5172V39.4483Z'
      fill='url(#paint7_linear_8745_29999)'
    />
    <path
      d='M112.276 36.4138C113.952 36.4138 115.31 37.7724 115.31 39.4483C115.31 41.1243 113.952 42.4828 112.276 42.4828C110.6 42.4828 109.241 41.1243 109.241 39.4483C109.241 37.7724 110.6 36.4138 112.276 36.4138Z'
      fill='url(#paint8_linear_8745_29999)'
    />
    <path
      d='M27.3103 48.5517H42.4828C45.8346 48.5517 48.5517 51.2689 48.5517 54.6207V57.6552C48.5517 59.3302 47.1923 60.6897 45.5172 60.6897C43.8422 60.6897 42.4828 59.3302 42.4828 57.6552V54.6207H36.4138V57.6552C36.4138 59.3302 35.0543 60.6897 33.3793 60.6897C31.7043 60.6897 30.3448 59.3302 30.3448 57.6552V54.6207H27.3103C25.6336 54.6207 24.2759 53.2612 24.2759 51.5862C24.2759 49.9112 25.6336 48.5517 27.3103 48.5517Z'
      fill='url(#paint9_linear_8745_29999)'
    />
    <path
      d='M94.069 24.2759H84.9655V18.2069H88C89.675 18.2069 91.0345 16.8475 91.0345 15.1724C91.0345 13.4974 89.675 12.1379 88 12.1379H84.9655V6.06896C84.9655 2.71719 82.2484 0 78.8966 0H75.8621C74.1853 0 72.8276 1.35946 72.8276 3.03448C72.8276 4.70954 74.1853 6.06896 75.8621 6.06896H78.8966V18.2069H57.6552C55.9784 18.2069 54.6207 19.5664 54.6207 21.2414C54.6207 22.9164 55.9784 24.2759 57.6552 24.2759H60.6897V30.3448H54.6207C51.2689 30.3448 48.5517 33.062 48.5517 36.4138V42.4828C48.5517 45.8346 51.2689 48.5517 54.6207 48.5517V54.6207C54.6207 57.9725 57.3378 60.6897 60.6897 60.6897C64.0415 60.6897 66.7586 57.9725 66.7586 54.6207H69.7931C71.4681 54.6207 72.8276 53.2612 72.8276 51.5862C72.8276 49.9112 71.4681 48.5517 69.7931 48.5517H60.6897C60.6897 45.1999 57.9725 42.4828 54.6207 42.4828V36.4138H60.6897V39.4483C60.6897 41.1233 62.0491 42.4828 63.7241 42.4828C65.3992 42.4828 66.7586 41.1233 66.7586 39.4483V30.3448C70.1104 30.3448 72.8276 27.6277 72.8276 24.2759H78.8966V30.3448C78.8966 33.6966 81.6137 36.4138 84.9655 36.4138V39.4483C84.9655 41.1233 86.325 42.4828 88 42.4828C89.675 42.4828 91.0345 41.1233 91.0345 39.4483V30.3448H94.069C95.744 30.3448 97.1034 28.9854 97.1034 27.3103C97.1034 25.6353 95.744 24.2759 94.069 24.2759Z'
      fill='url(#paint10_linear_8745_29999)'
    />
    <path
      d='M15.1724 54.6207H21.2414C22.9164 54.6207 24.2759 55.9801 24.2759 57.6552C24.2759 59.3302 22.9164 60.6897 21.2414 60.6897H15.1724C13.4956 60.6897 12.1379 59.3302 12.1379 57.6552C12.1379 55.9801 13.4956 54.6207 15.1724 54.6207Z'
      fill='url(#paint11_linear_8745_29999)'
    />
    <path
      d='M81.931 54.6207C83.607 54.6207 84.9655 55.9793 84.9655 57.6552C84.9655 59.3312 83.607 60.6897 81.931 60.6897C80.2551 60.6897 78.8966 59.3312 78.8966 57.6552C78.8966 55.9793 80.2551 54.6207 81.931 54.6207Z'
      fill='url(#paint12_linear_8745_29999)'
    />
    <path
      d='M115.31 57.6552C115.31 55.9793 113.952 54.6207 112.276 54.6207C110.6 54.6207 109.241 55.9793 109.241 57.6552C109.241 59.3312 110.6 60.6897 112.276 60.6897C113.952 60.6897 115.31 59.3312 115.31 57.6552Z'
      fill='url(#paint13_linear_8745_29999)'
    />
    <path
      d='M9.10345 60.6897C10.7794 60.6897 12.1379 62.0483 12.1379 63.7242C12.1379 65.4001 10.7794 66.7587 9.10345 66.7587C7.42756 66.7587 6.06896 65.4001 6.06896 63.7242C6.06896 62.0483 7.42756 60.6897 9.10345 60.6897Z'
      fill='url(#paint14_linear_8745_29999)'
    />
    <path
      d='M30.3448 63.7242C30.3448 62.0483 28.9863 60.6897 27.3103 60.6897C25.6345 60.6897 24.2759 62.0483 24.2759 63.7242C24.2759 65.4001 25.6345 66.7587 27.3103 66.7587C28.9863 66.7587 30.3448 65.4001 30.3448 63.7242Z'
      fill='url(#paint15_linear_8745_29999)'
    />
    <path
      d='M39.4483 60.6897C41.1242 60.6897 42.4828 62.0483 42.4828 63.7242C42.4828 65.4001 41.1242 66.7587 39.4483 66.7587C37.7724 66.7587 36.4138 65.4001 36.4138 63.7242C36.4138 62.0483 37.7724 60.6897 39.4483 60.6897Z'
      fill='url(#paint16_linear_8745_29999)'
    />
    <path
      d='M15.1724 66.7586H18.2069C21.5587 66.7586 24.2759 69.4758 24.2759 72.8276V75.8621C24.2759 77.5371 22.9164 78.8965 21.2414 78.8965C19.5663 78.8965 18.2069 77.5371 18.2069 75.8621V72.8276H15.1724C13.4956 72.8276 12.1379 71.4681 12.1379 69.7931C12.1379 68.1181 13.4956 66.7586 15.1724 66.7586Z'
      fill='url(#paint17_linear_8745_29999)'
    />
    <path
      d='M39.4483 72.8276H36.4138V69.7931C36.4138 68.1181 35.0543 66.7586 33.3793 66.7586C31.7043 66.7586 30.3448 68.1181 30.3448 69.7931V72.8276C30.3448 76.1794 33.062 78.8965 36.4138 78.8965H39.4483C41.1233 78.8965 42.4828 77.5371 42.4828 75.8621C42.4828 74.187 41.1233 72.8276 39.4483 72.8276Z'
      fill='url(#paint18_linear_8745_29999)'
    />
    <path
      d='M48.5517 69.7931C48.5517 68.1172 47.1932 66.7587 45.5172 66.7587C43.8414 66.7587 42.4828 68.1172 42.4828 69.7931C42.4828 71.4691 43.8414 72.8276 45.5172 72.8276C47.1932 72.8276 48.5517 71.4691 48.5517 69.7931Z'
      fill='url(#paint19_linear_8745_29999)'
    />
    <path
      d='M57.6552 66.7587C59.3311 66.7587 60.6897 68.1172 60.6897 69.7931C60.6897 71.4691 59.3311 72.8276 57.6552 72.8276C55.9793 72.8276 54.6207 71.4691 54.6207 69.7931C54.6207 68.1172 55.9793 66.7587 57.6552 66.7587Z'
      fill='url(#paint20_linear_8745_29999)'
    />
    <path
      d='M121.379 66.7586C121.379 63.4068 124.096 60.6897 127.448 60.6897C130.8 60.6897 133.517 63.4068 133.517 66.7586C133.517 70.1104 130.8 72.8276 127.448 72.8276V81.931C127.448 83.6061 126.089 84.9655 124.414 84.9655C122.739 84.9655 121.379 83.6061 121.379 81.931V72.8276H118.345C116.668 72.8276 115.31 71.4681 115.31 69.7931C115.31 68.1181 116.668 66.7586 118.345 66.7586H121.379Z'
      fill='url(#paint21_linear_8745_29999)'
    />
    <path
      d='M142.621 66.7586C140.946 66.7586 139.586 68.1181 139.586 69.7931V75.8621C139.586 77.5371 140.946 78.8965 142.621 78.8965C144.296 78.8965 145.655 77.5371 145.655 75.8621V69.7931C145.655 68.1181 144.296 66.7586 142.621 66.7586Z'
      fill='url(#paint22_linear_8745_29999)'
    />
    <path
      d='M169.931 51.5862V54.6207C173.283 54.6207 176 57.3379 176 60.6897V75.8621C176 77.5371 174.641 78.8965 172.965 78.8965C171.29 78.8965 169.931 77.5371 169.931 75.8621V72.8276H166.897C165.22 72.8276 163.862 71.4681 163.862 69.7931C163.862 68.1181 165.22 66.7586 166.897 66.7586H169.931V60.6897H163.862C163.862 64.0415 161.145 66.7586 157.793 66.7586V75.8621C157.793 77.5371 156.434 78.8965 154.759 78.8965C153.084 78.8965 151.724 77.5371 151.724 75.8621V60.6897H145.655C142.303 60.6897 139.586 57.9725 139.586 54.6207H136.552C134.875 54.6207 133.517 53.2612 133.517 51.5862C133.517 49.9112 134.875 48.5517 136.552 48.5517H139.586C142.938 48.5517 145.655 51.2689 145.655 54.6207H163.862V51.5862C163.862 49.9112 165.222 48.5517 166.897 48.5517C168.572 48.5517 169.931 49.9112 169.931 51.5862Z'
      fill='url(#paint23_linear_8745_29999)'
    />
    <path
      d='M57.6552 145.655H54.6207V127.448C54.6207 124.096 51.9035 121.379 48.5517 121.379V118.345C48.5517 116.67 47.1923 115.31 45.5172 115.31C43.8422 115.31 42.4828 116.67 42.4828 118.345V121.379H36.4138V115.31H39.4483C41.1233 115.31 42.4828 113.951 42.4828 112.276C42.4828 110.601 41.1233 109.241 39.4483 109.241H30.3448V103.172H45.5172C47.1923 103.172 48.5517 101.813 48.5517 100.138C48.5517 98.4629 47.1923 97.1034 45.5172 97.1034H6.06896V78.8965H9.10345C10.7785 78.8965 12.1379 77.5371 12.1379 75.8621C12.1379 74.187 10.7785 72.8276 9.10345 72.8276H6.06896C2.71716 72.8276 0 75.5448 0 78.8965V106.207C0 107.882 1.35945 109.241 3.03448 109.241C4.70951 109.241 6.06896 107.882 6.06896 106.207V103.172H18.2069V124.414C18.2069 126.089 19.5663 127.448 21.2414 127.448C22.9164 127.448 24.2759 126.089 24.2759 124.414V115.31H30.3448V121.379C30.3448 124.731 33.062 127.448 36.4138 127.448H48.5517V145.655C48.5517 149.007 51.2689 151.724 54.6207 151.724H57.6552C59.3302 151.724 60.6897 150.365 60.6897 148.69C60.6897 147.015 59.3302 145.655 57.6552 145.655Z'
      fill='url(#paint24_linear_8745_29999)'
    />
    <path
      d='M54.6207 75.8621C54.6207 74.1862 53.2622 72.8276 51.5862 72.8276C49.9103 72.8276 48.5517 74.1862 48.5517 75.8621C48.5517 77.5381 49.9103 78.8966 51.5862 78.8966C53.2622 78.8966 54.6207 77.5381 54.6207 75.8621Z'
      fill='url(#paint25_linear_8745_29999)'
    />
    <path
      d='M27.3103 78.8966C28.9863 78.8966 30.3448 80.2552 30.3448 81.9311C30.3448 83.607 28.9863 84.9655 27.3103 84.9655C25.6345 84.9655 24.2759 83.607 24.2759 81.9311C24.2759 80.2552 25.6345 78.8966 27.3103 78.8966Z'
      fill='url(#paint26_linear_8745_29999)'
    />
    <path
      d='M48.5517 81.9311C48.5517 80.2552 47.1932 78.8966 45.5172 78.8966C43.8414 78.8966 42.4828 80.2552 42.4828 81.9311C42.4828 83.607 43.8414 84.9655 45.5172 84.9655C47.1932 84.9655 48.5517 83.607 48.5517 81.9311Z'
      fill='url(#paint27_linear_8745_29999)'
    />
    <path
      d='M136.552 78.8966C138.228 78.8966 139.586 80.2552 139.586 81.9311C139.586 83.607 138.228 84.9655 136.552 84.9655C134.876 84.9655 133.517 83.607 133.517 81.9311C133.517 80.2552 134.876 78.8966 136.552 78.8966Z'
      fill='url(#paint28_linear_8745_29999)'
    />
    <path
      d='M145.655 81.931C145.655 80.256 147.015 78.8965 148.69 78.8965C150.365 78.8965 151.724 80.256 151.724 81.931V88C151.724 89.675 150.365 91.0345 148.69 91.0345C147.015 91.0345 145.655 89.675 145.655 88V81.931Z'
      fill='url(#paint29_linear_8745_29999)'
    />
    <path
      d='M160.828 78.8965C159.151 78.8965 157.793 80.256 157.793 81.931C157.793 83.6061 159.151 84.9655 160.828 84.9655H166.897C168.572 84.9655 169.931 83.6061 169.931 81.931C169.931 80.256 168.572 78.8965 166.897 78.8965H160.828Z'
      fill='url(#paint30_linear_8745_29999)'
    />
    <path
      d='M24.2759 88C24.2759 86.3241 22.9173 84.9655 21.2414 84.9655C19.5655 84.9655 18.2069 86.3241 18.2069 88C18.2069 89.676 19.5655 91.0345 21.2414 91.0345C22.9173 91.0345 24.2759 89.676 24.2759 88Z'
      fill='url(#paint31_linear_8745_29999)'
    />
    <path
      d='M39.4483 84.9655C41.1242 84.9655 42.4828 86.3241 42.4828 88C42.4828 89.676 41.1242 91.0345 39.4483 91.0345C37.7724 91.0345 36.4138 89.676 36.4138 88C36.4138 86.3241 37.7724 84.9655 39.4483 84.9655Z'
      fill='url(#paint32_linear_8745_29999)'
    />
    <path
      d='M54.6207 88C54.6207 86.3241 53.2622 84.9655 51.5862 84.9655C49.9103 84.9655 48.5517 86.3241 48.5517 88C48.5517 89.676 49.9103 91.0345 51.5862 91.0345C53.2622 91.0345 54.6207 89.676 54.6207 88Z'
      fill='url(#paint33_linear_8745_29999)'
    />
    <path
      d='M130.483 84.9655C132.159 84.9655 133.517 86.3241 133.517 88C133.517 89.676 132.159 91.0345 130.483 91.0345C128.807 91.0345 127.448 89.676 127.448 88C127.448 86.3241 128.807 84.9655 130.483 84.9655Z'
      fill='url(#paint34_linear_8745_29999)'
    />
    <path
      d='M176 88C176 86.3241 174.641 84.9655 172.965 84.9655C171.29 84.9655 169.931 86.3241 169.931 88C169.931 89.676 171.29 91.0345 172.965 91.0345C174.641 91.0345 176 89.676 176 88Z'
      fill='url(#paint35_linear_8745_29999)'
    />
    <path
      d='M57.6552 91.0345C55.9801 91.0345 54.6207 92.3939 54.6207 94.069V100.138C54.6207 101.813 55.9801 103.172 57.6552 103.172C59.3302 103.172 60.6897 101.813 60.6897 100.138V94.069C60.6897 92.3939 59.3302 91.0345 57.6552 91.0345Z'
      fill='url(#paint36_linear_8745_29999)'
    />
    <path
      d='M127.448 94.069V97.1034C130.8 97.1034 133.517 99.8206 133.517 103.172V106.207C133.517 107.882 132.158 109.241 130.483 109.241C128.808 109.241 127.448 107.882 127.448 106.207V103.172H118.345C116.668 103.172 115.31 101.813 115.31 100.138C115.31 98.4629 116.668 97.1034 118.345 97.1034H121.379V94.069C121.379 92.3939 122.739 91.0345 124.414 91.0345C126.089 91.0345 127.448 92.3939 127.448 94.069Z'
      fill='url(#paint37_linear_8745_29999)'
    />
    <path
      d='M136.552 91.0345C134.875 91.0345 133.517 92.3939 133.517 94.069C133.517 95.744 134.875 97.1034 136.552 97.1034H142.621C144.296 97.1034 145.655 95.744 145.655 94.069C145.655 92.3939 144.296 91.0345 142.621 91.0345H136.552Z'
      fill='url(#paint38_linear_8745_29999)'
    />
    <path
      d='M157.793 94.069C157.793 92.3931 156.435 91.0345 154.759 91.0345C153.083 91.0345 151.724 92.3931 151.724 94.069C151.724 95.745 153.083 97.1035 154.759 97.1035C156.435 97.1035 157.793 95.745 157.793 94.069Z'
      fill='url(#paint39_linear_8745_29999)'
    />
    <path
      d='M169.931 97.1034H166.897C165.22 97.1034 163.862 98.4629 163.862 100.138C163.862 101.813 165.22 103.172 166.897 103.172H169.931V112.276C169.931 113.951 171.29 115.31 172.965 115.31C174.641 115.31 176 113.951 176 112.276V103.172C176 99.8206 173.283 97.1034 169.931 97.1034Z'
      fill='url(#paint40_linear_8745_29999)'
    />
    <path
      d='M78.8966 118.345C78.8966 116.669 77.538 115.31 75.8621 115.31C74.1862 115.31 72.8276 116.669 72.8276 118.345C72.8276 120.021 74.1862 121.379 75.8621 121.379C77.538 121.379 78.8966 120.021 78.8966 118.345Z'
      fill='url(#paint41_linear_8745_29999)'
    />
    <path
      d='M57.6552 109.241H51.5862C49.9094 109.241 48.5517 110.601 48.5517 112.276C48.5517 113.951 49.9094 115.31 51.5862 115.31H57.6552C59.3302 115.31 60.6897 113.951 60.6897 112.276C60.6897 110.601 59.3302 109.241 57.6552 109.241Z'
      fill='url(#paint42_linear_8745_29999)'
    />
    <path
      d='M0 124.414C0 122.739 1.35772 121.379 3.03448 121.379H9.10345C10.7785 121.379 12.1379 122.739 12.1379 124.414C12.1379 126.089 10.7785 127.448 9.10345 127.448H3.03448C1.35772 127.448 0 126.089 0 124.414Z'
      fill='url(#paint43_linear_8745_29999)'
    />
    <path
      d='M75.8621 133.517H72.8276V124.414C72.8276 122.739 71.4681 121.379 69.7931 121.379C68.1181 121.379 66.7586 122.739 66.7586 124.414V157.793H60.6897C57.3378 157.793 54.6207 160.51 54.6207 163.862C54.6207 167.214 57.3378 169.931 60.6897 169.931C60.6897 173.283 63.4068 176 66.7586 176H69.7931C71.4681 176 72.8276 174.641 72.8276 172.965C72.8276 171.29 71.4681 169.931 69.7931 169.931H66.7586V163.862C70.1104 163.862 72.8276 161.145 72.8276 157.793V139.586H75.8621C77.5371 139.586 78.8966 138.227 78.8966 136.552C78.8966 134.877 77.5371 133.517 75.8621 133.517Z'
      fill='url(#paint44_linear_8745_29999)'
    />
    <path
      d='M169.931 124.414C169.931 122.738 168.573 121.379 166.897 121.379C165.221 121.379 163.862 122.738 163.862 124.414C163.862 126.09 165.221 127.448 166.897 127.448C168.573 127.448 169.931 126.09 169.931 124.414Z'
      fill='url(#paint45_linear_8745_29999)'
    />
    <path
      d='M160.828 127.448C162.504 127.448 163.862 128.807 163.862 130.483C163.862 132.159 162.504 133.517 160.828 133.517C159.152 133.517 157.793 132.159 157.793 130.483C157.793 128.807 159.152 127.448 160.828 127.448Z'
      fill='url(#paint46_linear_8745_29999)'
    />
    <path
      d='M103.172 136.552C103.172 134.877 104.532 133.517 106.207 133.517C107.882 133.517 109.241 134.877 109.241 136.552V142.621C109.241 144.296 107.882 145.655 106.207 145.655C104.532 145.655 103.172 144.296 103.172 142.621V136.552Z'
      fill='url(#paint47_linear_8745_29999)'
    />
    <path
      d='M172.965 127.448C171.29 127.448 169.931 128.808 169.931 130.483V133.517H166.897C165.22 133.517 163.862 134.877 163.862 136.552C163.862 138.227 165.22 139.586 166.897 139.586H169.931V142.621C169.931 144.296 171.29 145.655 172.965 145.655C174.641 145.655 176 144.296 176 142.621V130.483C176 128.808 174.641 127.448 172.965 127.448Z'
      fill='url(#paint48_linear_8745_29999)'
    />
    <path
      d='M127.448 169.931H136.552C138.227 169.931 139.586 171.29 139.586 172.965C139.586 174.641 138.227 176 136.552 176H127.448C124.096 176 121.379 173.283 121.379 169.931V163.862H115.31V166.897C115.31 168.572 113.951 169.931 112.276 169.931C110.601 169.931 109.241 168.572 109.241 166.897V163.862C105.89 163.862 103.172 161.145 103.172 157.793V154.759C103.172 153.084 104.532 151.724 106.207 151.724C107.882 151.724 109.241 153.084 109.241 154.759V157.793H130.483C132.158 157.793 133.517 159.153 133.517 160.828C133.517 162.503 132.158 163.862 130.483 163.862H127.448V169.931Z'
      fill='url(#paint49_linear_8745_29999)'
    />
    <path
      d='M136.552 133.517C138.228 133.517 139.586 134.876 139.586 136.552C139.586 138.228 138.228 139.586 136.552 139.586C134.876 139.586 133.517 138.228 133.517 136.552C133.517 134.876 134.876 133.517 136.552 133.517Z'
      fill='url(#paint50_linear_8745_29999)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M157.793 109.241V121.379C157.793 124.731 155.076 127.448 151.724 127.448V139.586H154.759C156.434 139.586 157.793 140.946 157.793 142.621C157.793 144.296 156.434 145.655 154.759 145.655H151.724C151.724 149.007 149.007 151.724 145.655 151.724V154.759C145.655 156.434 144.296 157.793 142.621 157.793C140.946 157.793 139.586 156.434 139.586 154.759V151.724H112.276C110.599 151.724 109.241 150.365 109.241 148.69C109.241 147.015 110.599 145.655 112.276 145.655H115.31V139.586C115.31 136.234 118.027 133.517 121.379 133.517V127.448H115.31V130.483C115.31 132.158 113.951 133.517 112.276 133.517C110.601 133.517 109.241 132.158 109.241 130.483V127.448H103.172C103.172 130.8 100.455 133.517 97.1034 133.517V145.655H100.138C101.813 145.655 103.172 147.015 103.172 148.69C103.172 150.365 101.813 151.724 100.138 151.724H97.1034V160.828C97.1034 162.503 95.744 163.862 94.069 163.862C92.3939 163.862 91.0345 162.503 91.0345 160.828V151.724H81.931C80.2543 151.724 78.8966 150.365 78.8966 148.69C78.8966 147.015 80.2543 145.655 81.931 145.655H84.9655C84.9655 142.303 87.6827 139.586 91.0345 139.586V133.517H81.931C80.2543 133.517 78.8966 132.158 78.8966 130.483C78.8966 128.808 80.2543 127.448 81.931 127.448H97.1034C97.1034 124.096 99.8206 121.379 103.172 121.379C103.172 118.027 105.89 115.31 109.241 115.31H115.31C118.662 115.31 121.379 118.027 121.379 121.379H151.724V109.241H145.655V112.276C145.655 113.951 144.296 115.31 142.621 115.31C140.946 115.31 139.586 113.951 139.586 112.276V109.241C139.586 105.89 142.303 103.172 145.655 103.172V100.138C145.655 98.4629 147.015 97.1034 148.69 97.1034C150.365 97.1034 151.724 98.4629 151.724 100.138V103.172C155.076 103.172 157.793 105.89 157.793 109.241ZM127.448 145.655H145.655V127.448H127.448V145.655Z'
      fill='url(#paint51_linear_8745_29999)'
    />
    <path
      d='M106.207 169.931H103.172V166.897C103.172 165.222 101.813 163.862 100.138 163.862C98.4629 163.862 97.1034 165.222 97.1034 166.897V169.931H91.0345C91.0345 166.579 88.3173 163.862 84.9655 163.862V160.828C84.9655 159.153 83.6061 157.793 81.931 157.793C80.256 157.793 78.8966 159.153 78.8966 160.828V163.862H75.8621C74.1853 163.862 72.8276 165.222 72.8276 166.897C72.8276 168.572 74.1853 169.931 75.8621 169.931H84.9655C84.9655 173.283 87.6827 176 91.0345 176H106.207C107.882 176 109.241 174.641 109.241 172.965C109.241 171.29 107.882 169.931 106.207 169.931Z'
      fill='url(#paint52_linear_8745_29999)'
    />
    <path
      d='M169.931 148.69V157.793C173.283 157.793 176 160.51 176 163.862C176 167.214 173.283 169.931 169.931 169.931V172.965C169.931 174.641 168.572 176 166.897 176C165.222 176 163.862 174.641 163.862 172.965V163.862H160.828C159.151 163.862 157.793 162.503 157.793 160.828C157.793 159.153 159.151 157.793 160.828 157.793H163.862V148.69C163.862 147.015 165.222 145.655 166.897 145.655C168.572 145.655 169.931 147.015 169.931 148.69Z'
      fill='url(#paint53_linear_8745_29999)'
    />
    <path
      d='M142.621 163.862C144.297 163.862 145.655 165.221 145.655 166.897C145.655 168.573 144.297 169.931 142.621 169.931C140.945 169.931 139.586 168.573 139.586 166.897C139.586 165.221 140.945 163.862 142.621 163.862Z'
      fill='url(#paint54_linear_8745_29999)'
    />
    <path
      d='M157.793 166.897C157.793 165.221 156.435 163.862 154.759 163.862C153.083 163.862 151.724 165.221 151.724 166.897C151.724 168.573 153.083 169.931 154.759 169.931C156.435 169.931 157.793 168.573 157.793 166.897Z'
      fill='url(#paint55_linear_8745_29999)'
    />
    <path
      d='M148.69 169.931C150.366 169.931 151.724 171.29 151.724 172.966C151.724 174.641 150.366 176 148.69 176C147.014 176 145.655 174.641 145.655 172.966C145.655 171.29 147.014 169.931 148.69 169.931Z'
      fill='url(#paint56_linear_8745_29999)'
    />
    <path
      d='M12.1354 25.464C12.1354 28.1542 14.3703 30.3449 17.1177 30.3449L25.3676 30.3447C28.1127 30.3447 30.3474 28.1538 30.3474 25.4638V17.0161C30.3474 14.3261 28.1125 12.138 25.3676 12.138H17.115C16.0535 12.138 15.0674 12.4659 14.2585 13.0226C13.6287 13.4545 13.1058 14.0269 12.7364 14.6929C12.3539 15.385 12.1354 16.1759 12.1354 17.0163V25.464Z'
      fill='url(#paint57_linear_8745_29999)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.9787 0.00344094H14.504C7.93705 0.00344094 2.37351 4.3422 0.594759 10.2724C0.410808 10.8858 0.267217 11.5163 0.167382 12.1607C0.057352 12.8719 0 13.6 0 14.3414V28.1359C0 36.0458 6.50836 42.4781 14.504 42.4781L27.9787 42.4777C35.974 42.4777 42.4828 36.0454 42.4828 28.1355V14.3414C42.4828 6.43533 35.9744 0.00344094 27.9787 0.00344094ZM36.1103 28.1359C36.1103 32.5304 32.4628 36.1057 27.977 36.1057L14.5057 36.1053C10.02 36.1053 6.37241 32.5299 6.37241 28.1359V14.3414C6.37241 12.9688 6.72842 11.6764 7.35504 10.5472C7.95829 9.46095 8.81177 8.52676 9.83943 7.81942C11.1606 6.90986 12.7695 6.37585 14.5036 6.37585H27.9787C32.4628 6.37585 36.1103 9.94908 36.1103 14.3414V28.1359Z'
      fill='url(#paint58_linear_8745_29999)'
    />
    <path
      d='M145.653 25.464C145.653 28.1542 147.888 30.3449 150.635 30.3449L158.885 30.3447C161.63 30.3447 163.865 28.1538 163.865 25.4638V17.0161C163.865 14.3261 161.63 12.138 158.885 12.138H150.632C149.571 12.138 148.585 12.4659 147.776 13.0226C147.146 13.4545 146.623 14.0269 146.254 14.6929C145.871 15.385 145.653 16.1759 145.653 17.0163V25.464Z'
      fill='url(#paint59_linear_8745_29999)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M161.496 0.00344094H148.021C141.454 0.00344094 135.891 4.3422 134.112 10.2724C133.928 10.8858 133.784 11.5163 133.685 12.1607C133.575 12.8719 133.517 13.6 133.517 14.3414V28.1359C133.517 36.0458 140.026 42.4781 148.021 42.4781L161.496 42.4777C169.491 42.4777 176 36.0454 176 28.1355V14.3414C176 6.43533 169.492 0.00344094 161.496 0.00344094ZM169.628 28.1359C169.628 32.5304 165.98 36.1057 161.494 36.1057L148.023 36.1053C143.537 36.1053 139.89 32.5299 139.89 28.1359V14.3414C139.89 12.9688 140.246 11.6764 140.872 10.5472C141.476 9.46095 142.329 8.52676 143.357 7.81942C144.678 6.90986 146.287 6.37585 148.021 6.37585H161.496C165.98 6.37585 169.628 9.94908 169.628 14.3414V28.1359Z'
      fill='url(#paint60_linear_8745_29999)'
    />
    <path
      d='M12.1354 158.981C12.1354 161.671 14.3703 163.862 17.1177 163.862L25.3676 163.862C28.1127 163.862 30.3474 161.671 30.3474 158.981V150.533C30.3474 147.843 28.1125 145.655 25.3676 145.655H17.115C16.0535 145.655 15.0674 145.983 14.2585 146.54C13.6287 146.972 13.1058 147.544 12.7364 148.21C12.3539 148.902 12.1354 149.693 12.1354 150.534V158.981Z'
      fill='url(#paint61_linear_8745_29999)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.9787 133.521H14.504C7.93705 133.521 2.37351 137.859 0.594759 143.79C0.410808 144.403 0.267217 145.033 0.167382 145.678C0.057352 146.389 0 147.117 0 147.859V161.653C0 169.563 6.50836 175.995 14.504 175.995L27.9787 175.995C35.974 175.995 42.4828 169.563 42.4828 161.653V147.859C42.4828 139.953 35.9744 133.521 27.9787 133.521ZM36.1103 161.653C36.1103 166.048 32.4628 169.623 27.977 169.623L14.5057 169.622C10.02 169.622 6.37241 166.047 6.37241 161.653V147.859C6.37241 146.486 6.72842 145.194 7.35504 144.064C7.95829 142.978 8.81177 142.044 9.83943 141.337C11.1606 140.427 12.7695 139.893 14.5036 139.893H27.9787C32.4628 139.893 36.1103 143.466 36.1103 147.859V161.653Z'
      fill='url(#paint62_linear_8745_29999)'
    />
    <path
      d='M98.3822 87.92L98.3216 87.8821C95.7903 86.3071 92.1468 85.2562 87.9988 85.2562C83.8507 85.2562 80.2072 86.3071 77.676 87.8821C77.6135 87.921 77.5519 87.96 77.4912 87.9992C77.5357 88.0279 77.5806 88.0566 77.626 88.0851L77.6784 88.1179C80.2097 89.6929 83.8532 90.7438 88.0012 90.7438C92.1493 90.7438 95.7928 89.6929 98.324 88.1179C98.3865 88.079 98.4481 88.04 98.5088 88.0008C98.4923 87.9902 98.4758 87.9795 98.4591 87.9689C98.4336 87.9526 98.408 87.9363 98.3822 87.92Z'
      fill='url(#paint63_linear_8745_29999)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M112 88C112 101.255 101.255 112 88 112C74.7452 112 64 101.255 64 88C64 74.7452 74.7452 64 88 64C101.255 64 112 74.7452 112 88ZM100.405 89.5491C101.505 90.7165 102.007 91.9474 102.007 93.0914C102.007 94.8011 100.886 96.7049 98.3216 98.3007C95.7903 99.8757 92.1468 100.927 87.9988 100.927C83.8507 100.927 80.2072 99.8757 77.676 98.3007C75.1113 96.7049 73.9909 94.8007 73.9909 93.091L71.6364 93.0914C71.6364 98.7144 78.9621 103.273 87.9988 103.273C97.0355 103.273 104.361 98.7144 104.361 93.0914C104.361 87.4684 97.0355 82.9101 87.9988 82.9101C83.0397 82.9101 78.596 84.2828 75.5953 86.4509C74.495 85.2835 73.9934 84.0526 73.9934 82.9086C73.9934 81.1989 75.1138 79.2951 77.6784 77.6993C80.2097 76.1243 83.8532 75.0734 88.0012 75.0734C92.1493 75.0734 95.7928 76.1243 98.324 77.6993C100.889 79.2951 102.009 81.1993 102.009 82.909L104.364 82.9086C104.364 77.2856 97.0379 72.7273 88.0012 72.7273C78.9645 72.7273 71.6388 77.2856 71.6388 82.9086C71.6388 88.5316 78.9645 93.0899 88.0012 93.0899C92.9603 93.0899 97.4041 91.7172 100.405 89.5491Z'
      fill='url(#paint64_linear_8745_29999)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint6_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint7_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint8_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint9_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint10_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint11_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint12_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint13_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint14_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint15_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint16_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint17_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint18_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint19_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint20_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint21_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint22_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint23_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint24_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint25_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint26_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint27_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint28_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint29_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint30_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint31_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint32_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint33_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint34_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint35_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint36_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint37_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint38_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint39_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint40_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint41_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint42_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint43_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint44_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint45_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint46_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint47_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint48_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint49_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint50_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint51_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint52_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint53_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint54_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint55_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint56_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint57_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint58_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint59_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint60_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint61_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint62_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint63_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint64_linear_8745_29999'
        x1={-1.07618}
        y1={85.8354}
        x2={177.102}
        y2={88.0693}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C7ACFF' />
        <stop offset={0.8706} stopColor='white' />
      </linearGradient>
    </defs>
  </svg>
);
