import { colors } from 'app/colors';
import { BalanceRow, CircleBg } from 'feature/game/balance';

import { Button } from 'shared/button';

import { CoinIcon } from 'shared/icon';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import { Text } from 'shared/typography';
import { CheckMark, Wrapper } from './FakeMint.styled';

import { Gradient } from 'shared/gradient';
import { BonusContainer } from 'shared/layout';

import { useAppSelector } from 'app/store/rootStore';
import { selectBalance } from 'entities/user';

export const CompliteTasks = ({ onNext }: { onNext: () => void }) => {
  const walletBalance = useAppSelector(selectBalance);
  return (
    <Wrapper>
      <CheckMark>
        <DivDecoration width={10} height={'72px'} />
        <BonusContainer width='80px' height='80px'>
          <CoinIcon size={48} color={colors.secondaryColor} />
        </BonusContainer>
        <DivDecoration width={10} isReverse height={'72px'} />
      </CheckMark>
      <div style={{ marginTop: 20, marginBottom: 14, textAlign: 'center' }}>
        <Text style={{ fontSize: 20 }}>
          Complete tasks
          <br /> and check-in daily
        </Text>

        <Text
          style={{ color: colors.fourthColor, letterSpacing: 2, marginTop: 10 }}
        >
          To mint your sint
        </Text>
      </div>
      <Text style={{ fontSize: 10, textAlign: 'center' }}>Remaining</Text>
      <div style={{ marginBottom: 20 }}>
        <BalanceRow>
          <span>{3000 - walletBalance}</span>
          <CircleBg>
            <CoinIcon color={colors.blackColor} />
          </CircleBg>
        </BalanceRow>
      </div>
      <Button
        onClick={onNext}
        decoration='basic'
        borderColor={colors.primaryColor}
        size='l'
      >
        <Gradient color={colors.primaryColor}>
          <Text>VIEW TASKS</Text>
        </Gradient>
      </Button>
    </Wrapper>
  );
};
