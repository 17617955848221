import { CheckmarkIcon } from 'shared/icon/Checkmark';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';

import {
  BlockContent,
  BonusContainer,
  BonusContent,
  CentredWrapper,
} from 'shared/layout';
import { Text } from 'shared/typography';

export const WaitListSuccessContent = () => {
  return (
    <CentredWrapper
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <DivDecoration height={'72px'} width={11} opacity={0.25} />
        <BonusContainer width='80px' height='80px'>
          <BonusContent>
            <CheckmarkIcon width={45} height={45} />
          </BonusContent>
        </BonusContainer>
        <DivDecoration isReverse height={'72px'} width={11} opacity={0.25} />
      </div>
      <BlockContent
        style={{
          marginTop: '18px',
        }}
      >
        <Text
          style={{
            fontSize: '20px',
          }}
        >
          you're in!
        </Text>
        <Text
          style={{
            fontSize: '12px',
            marginTop: '16px',
            lineHeight: '20px',
            letterSpacing: 4,
            marginBottom: '20px',
            maxWidth: 277,
          }}
        >
          Waitlist confirmed. <br />
          SINT unlocks for you soon.
        </Text>
      </BlockContent>
    </CentredWrapper>
  );
};
