import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { colors } from 'app/colors';
import { useAppSelector } from 'app/store/rootStore';
import { useSetWalletMutation } from 'entities/user/model/api';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { useToast } from 'shared/toast';
import { Text } from 'shared/typography';
import { useCompleteMainQuestMutation } from 'entities/tasks/api';
import { selectUser, setWalletTaskComplete } from 'entities/user';
import { selectTasks, setTaskComplete } from 'entities/tasks';

export const ConnectWallet = ({}) => {
  const [tonConnectUI] = useTonConnectUI();
  const { walletAddress } = useAppSelector(selectUser);
  const { mainTasks } = useAppSelector(selectTasks);
  const [setWallet, { isSuccess, isError }] = useSetWalletMutation();
  const { showToast } = useToast();
  const friendlyAdress = useTonAddress();

  const [completeMainQuest] = useCompleteMainQuestMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (friendlyAdress && walletAddress !== friendlyAdress) {
      setWallet(friendlyAdress)
        .unwrap()
        .then(() => {
          if (!walletAddress) {
            const walletTask = mainTasks.data.find(
              (task) => task.type === 'connect_wallet'
            );
            if (!!walletTask) {
              completeMainQuest(+walletTask.id)
                .unwrap()
                .then((response) => {
                  if (response?.accountQuest?.completed) {
                    dispatch(setTaskComplete(walletTask.id));
                    dispatch(setWalletTaskComplete(response.accountQuest));
                    showToast('Task checked successfully!', 'success');
                  } else {
                    showToast('Failed to complete the task', 'error');
                  }
                })
                .catch((error) => {
                  console.error('Failed to complete the task:', error);
                });
            }
          } else {
            console.warn('No task with type "connect_wallet" found.');
          }
        })
        .catch((error) => {
          if (error?.data?.message === 'Wallet is already set') {
            return;
          }
          showToast('Failed to set wallet', 'error');
        });
    }
  }, [friendlyAdress]);

  useEffect(() => {
    if (isError) {
      showToast('set failed!', 'error');
    }
  }, [isError]);

  const handleConnectClick = () => {
    if (!friendlyAdress) {
      tonConnectUI.modal.open();
    } else {
      tonConnectUI.disconnect();
    }
  };

  return (
    <Button
      color={colors.notIntensiveColor}
      borderColor={colors.primaryColor}
      decoration='basic'
      onClick={handleConnectClick}
      style={{ height: '56px', width: '160px' }}
    >
      <Gradient color={colors.primaryColor}>
        <Text>{friendlyAdress ? 'Disconnect' : 'Connect'}</Text>
      </Gradient>
    </Button>
  );
};
