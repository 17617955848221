import { colors } from 'app/colors';
import { IconProps } from '../icon.types';

export const SoonIcon: React.FC<IconProps> = ({
  color = colors.whiteColor,
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12 2C6.47717 2 2 6.47717 2 12C2 17.5228 6.47717 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47717 17.5228 2 12 2ZM12 21.25C6.89954 21.25 2.75 17.1005 2.75 12C2.75 6.89954 6.89954 2.75 12 2.75C17.1005 2.75 21.25 6.89954 21.25 12C21.25 17.1005 17.1005 21.25 12 21.25ZM17.25 11.625H12.375V5.75C12.375 5.54297 12.207 5.375 12 5.375C11.793 5.375 11.625 5.54297 11.625 5.75V12C11.625 12.207 11.793 12.375 12 12.375H17.25C17.457 12.375 17.625 12.207 17.625 12C17.625 11.793 17.457 11.625 17.25 11.625ZM2.37507 4.321C2.26814 4.321 2.16218 4.27559 2.08796 4.18721C1.95466 4.02852 1.97517 3.7917 2.13386 3.65889L5.19831 1.0876C5.35652 0.954787 5.59284 0.975295 5.72663 1.1335C5.85993 1.29219 5.83943 1.52901 5.68073 1.66182L2.61628 4.23311C2.54597 4.29219 2.46052 4.321 2.37507 4.321ZM21.9124 4.18721C22.0452 4.02852 22.0247 3.7917 21.8665 3.65889L18.803 1.0876C18.6458 0.955275 18.4085 0.974806 18.2747 1.1335C18.1419 1.29219 18.1624 1.52901 18.3206 1.66182L21.3841 4.23311C21.4544 4.29219 21.5403 4.321 21.6253 4.321C21.7317 4.321 21.8382 4.27559 21.9124 4.18721Z'
      fill={color}
    />
  </svg>
);
