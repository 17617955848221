import { FC } from 'react';
import { Text } from 'shared/typography';
import { Button } from 'shared/button';
import { colors } from 'app/colors';
import { Gradient } from 'shared/gradient';
import { Wrapper, TextBlock, CheckMark } from './welcomeModal.styled';
import { useModal } from 'shared/modal';
import { useCallback } from 'react';
import { BonusContainer } from 'shared/layout';
import { CoinIcon } from 'shared/icon';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';

export const WelcomeModal = () => {
  const { setIsOpen, setModalProps } = useModal();

  const showModal = useCallback(() => {
    const handleClose = () => setIsOpen(false);

    setModalProps({
      onRequestClose: handleClose,
      children: <ModalContent onNext={handleClose} />,
      isOpen: true,
    });
    setIsOpen(true);
  }, [setIsOpen, setModalProps]);

  return { showModal };
};

interface ModalContentProps {
  onNext: () => void;
}

const ModalContent: FC<ModalContentProps> = ({ onNext }) => {
  return (
    <Wrapper>
      <CheckMark>
        <DivDecoration width={10} height={'72px'} />
        <BonusContainer width='80px' height='80px'>
          <CoinIcon size={50} color={colors.secondaryColor} />
        </BonusContainer>
        <DivDecoration width={10} isReverse height={'72px'} />
      </CheckMark>
      <TextBlock>
        <Text style={{ fontSize: 20 }}>You're in - congrats!</Text>
        <Text
          style={{ color: colors.fourthColor, letterSpacing: 2, marginTop: 10 }}
        >
          Complete tasks,
          <br />
          earn points, mint your SINT
        </Text>
      </TextBlock>
      <Button
        onClick={onNext}
        decoration='basic'
        borderColor={colors.primaryColor}
        size='l'
      >
        <Gradient color={colors.primaryColor}>
          <Text>Get started</Text>
        </Gradient>
      </Button>
    </Wrapper>
  );
};
