import { FC, useMemo, useState } from 'react';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';

import { TaskItemProps } from '../tasks.types';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { CheckmarkIcon } from 'shared/icon/Checkmark';
import { Loader } from 'shared/loader';
import { colors } from 'app/colors';
import {
  Task,
  TaskName,
  StyledButtonIcon,
  TaskIcon,
  TaskContainer,
  TaskButton,
  TaskLink,
  OrangeLine,
} from '../Tasks.styled';
import { LogoIcon } from 'shared/icon/LogoIcon';
import {
  useCompleteMainQuestMutation,
  useClaimRewardMutation,
  useCompletePartnerQuestMutation,
  useClaimPartnerRewardMutation,
} from '../api';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectMainQuests,
  selectPartnerQuests,
  setWalletTaskComplete,
  setPartnersTaskComplete,
  updateBalance,
  selectRefCount,
  selectUser,
} from 'entities/user';
import {
  setTaskComplete,
  setPartnerComplete,
  setRewardComplete,
  setPartnerReward,
  selectCompletedTaskIds,
  selectPartnerTaskIds,
  selectRewardTaskIds,
  selectPartnerRewardIds,
} from 'entities/tasks';
import { ROUTES } from 'app/router/routes';
import { useToast } from 'shared/toast';
import { formatNumber } from 'shared/utils';
import { ProgressBar } from 'shared/progress';

export const TaskItem: FC<TaskItemProps> = ({
  case: caseType,
  task,
  isChecked,
  onCheck,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { mintDate, isMinted } = useSelector(selectUser);
  const mainQuests = useSelector(selectMainQuests);
  const partnerTasks = useSelector(selectPartnerQuests);
  const completedTaskIds = useSelector(selectCompletedTaskIds);
  const completedPartnerIds = useSelector(selectPartnerTaskIds);
  const rewardTaskIds = useSelector(selectRewardTaskIds);
  const PartnerRewardIds = useSelector(selectPartnerRewardIds);
  const refCount = useSelector(selectRefCount);

  const isSintMinted = useMemo(() => {
    const durationMintMinutes = process.env.REACT_APP_MINT_DURATION || 60000;

    // TODO REMOVE * 1000 when it will be fixed on server side.
    return (
      +new Date() >
        +new Date(+new Date(mintDate * 1000) + +durationMintMinutes) && isMinted
    );
  }, [isMinted, mintDate]);

  const mainQuest = useMemo(
    () => mainQuests.find((quest) => quest.mainQuestId === +task.id),
    [mainQuests]
  );

  const partnerQuest = useMemo(
    () => partnerTasks.find((quest) => quest.partnersQuestId === +task.id),
    [partnerTasks]
  );

  //@ts-ignore
  const tg = window.Telegram.WebApp;

  const [completeMainQuest] = useCompleteMainQuestMutation();
  const [completPartnerQuest] = useCompletePartnerQuestMutation();

  const [claimReward] = useClaimRewardMutation();
  const [claimPartner] = useClaimPartnerRewardMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);

  const isComplete =
    caseType === 'fetchMainTask'
      ? mainQuest?.completed || completedTaskIds.includes(task.id)
      : partnerQuest?.completed || completedPartnerIds.includes(task.id);

  const isReward =
    caseType === 'fetchMainTask'
      ? mainQuest?.claimed || rewardTaskIds.includes(task.id)
      : partnerQuest?.claimed || PartnerRewardIds.includes(task.id);

  const isClaimable = isComplete && !isReward;
  const isWalletTask = task.type === 'connect_wallet';
  const inviteTask = task.type === 'invite';
  const enterTask = task.type === 'enter';
  const checkInTask = task.type === 'check_in';
  const tgLinkTask = task.type === 'join_tg';
  const playTask = task.type === 'play';
  const tapTask = task.type === 'tap';
  const doneTask =
    (enterTask && task.completed) ||
    (playTask && task.completed) ||
    (tapTask && task.completed);

  const handleTaskClick = async () => {
    if (checkInTask) {
      if (!isComplete) {
        handleCheckClick();
      }
      return;
    }

    if (isWalletTask) {
      if (!isComplete) {
        navigate(ROUTES.WALLET);
        onCheck(+task.id);
      }
      return;
    }

    if (playTask || tapTask) {
      if (!isComplete && isSintMinted) {
        navigate(ROUTES.GAME);
      }
    }

    // for addHomeScreen task

    if (task.link && task.link.includes('addToHomeScreen')) {
      try {
        if (tg.addToHomeScreen) {
          tg.addToHomeScreen();
          onCheck(+task.id);
        } else {
          showToast(
            'This device does not support adding to home screen.',
            'error'
          );
        }
      } catch (error) {
        console.error('Failed to execute addToHomeScreen:', error);
      }
      return;
    }

    if (inviteTask && !isComplete) {
      setIsLoading(true);
      try {
        const response = await completeMainQuest(+task.id).unwrap();
        if (response?.accountQuest?.completed) {
          dispatch(setTaskComplete(task.id));
          dispatch(setWalletTaskComplete(response.accountQuest));
          showToast('Invite task checked successfully!', 'success');
        } else {
          throw new Error(
            `FAILED TO COMPLETE OF INVITE TASK "${task.name}" (ID: ${task.id}).`
          );
        }
      } catch (error) {
        console.error('Failed to complete invite task:', error);
        navigate(ROUTES.REFERRAL);
      } finally {
        setIsLoading(false);
      }
      return;
    }

    if (tgLinkTask) {
      tg.openTelegramLink(task.link);
      onCheck(+task.id);
      return;
    }

    if (task.link) {
      tg.openLink(task.link);
      onCheck(+task.id);
    }
  };

  const handleCheckClick = async (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    setIsLoading(true);

    try {
      let response;
      switch (caseType) {
        case 'fetchMainTask':
          response = await completeMainQuest(+task.id).unwrap();
          if (response?.accountQuest?.completed) {
            dispatch(setTaskComplete(task.id));
            dispatch(setWalletTaskComplete(response.accountQuest));
            showToast('Task checked successfully!', 'success');
          } else {
            throw new Error(
              `FAILED TO COMPLETE OF MAIN TASK "${task.name}" (ID: ${task.id}).`
            );
          }
          break;
        case 'fetchPartnerTask':
          response = await completPartnerQuest(+task.id).unwrap();
          if (response?.accountQuest?.completed) {
            dispatch(setPartnerComplete(task.id));
            dispatch(setPartnersTaskComplete(response.accountQuest));
            showToast('Task checked successfully!', 'success');
          } else {
            throw new Error(
              `FAILED TO COMPLETE OF PARTNER TASK "${task.name}" (ID: ${task.id}).`
            );
          }
          break;
        case 'fetchDailyTask':
        default:
          return;
      }
    } catch (error) {
      console.error('Failed to check the task:', error);
      showToast('Failed to check the task', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClaimClick = async (
    event: React.SyntheticEvent,
    id: string | number
  ) => {
    event.stopPropagation();

    const claimId =
      caseType === 'fetchPartnerTask' ? partnerQuest?.id : mainQuest?.id;

    if (!claimId) {
      showToast('Task ID is missing. Please check the task.', 'error');
      return;
    }

    setIsClaimLoading(true);

    try {
      let response;

      switch (caseType) {
        case 'fetchMainTask':
          response = await claimReward(+claimId).unwrap();
          if (response && response.accountQuest.claimed) {
            dispatch(setRewardComplete(task.id));
            dispatch(setWalletTaskComplete(response.accountQuest));
            dispatch(updateBalance(+response.walletBalance));
            showToast('Reward claimed successfully!', 'success');
          } else {
            throw new Error(
              `FAILED TO CLAIM OF MAIN TASK "${task.name}" (ID: ${task.id}).`
            );
          }
          break;

        case 'fetchPartnerTask':
          response = await claimPartner(+claimId).unwrap();
          if (response && response.accountQuest.claimed) {
            dispatch(setPartnerReward(task.id));
            dispatch(setPartnersTaskComplete(response.accountQuest));
            dispatch(updateBalance(+response.walletBalance));
            showToast('Reward claimed successfully!', 'success');
          } else {
            throw new Error(
              `FAILED TO CLAIM OF PARTNER TASK "${task.name}" (ID: ${task.id}).`
            );
          }
          break;
        case 'fetchDailyTask':
        default:
          showToast('Unknown task type. Please check the task.', 'error');
          return;
      }
    } catch (error) {
      console.error('Failed to claim the reward:', error);
      showToast('Failed to claim the reward', 'error');
    } finally {
      setIsClaimLoading(false);
    }
  };

  return (
    <Task>
      <TaskLink onClick={handleTaskClick}>
        <TaskContainer
          style={{
            opacity: doneTask || isReward ? 0.5 : 1,
          }}
        >
          {(isReward || doneTask) && <OrangeLine />}
          <TaskIcon>
            {task.icon ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(task.icon.svg),
                }}
                style={{ width: '24px', height: '24px' }}
              />
            ) : (
              <LogoIcon width={24} height={24} />
            )}
          </TaskIcon>
          <TaskName style={{ opacity: isReward ? 0.5 : 1 }}>
            {task.name}
          </TaskName>
        </TaskContainer>
        <TaskButton>
          {isReward || doneTask ? (
            <Button
              decoration='basic'
              borderColor={colors.btnDoneDecoration}
              size='s'
              color={colors.btnDoneBackground}
              style={{
                color: colors.secondaryColor,
                letterSpacing: '3.6px',
                fontSize: '14px',
                fontWeight: '700',
                borderTop: 'none',
                borderBottom: 'none',
              }}
            >
              <StyledButtonIcon style={{ margin: '0 4px 0 0' }}>
                <CheckmarkIcon width={16} height={16} />
              </StyledButtonIcon>
              DONE
            </Button>
          ) : isClaimLoading ? (
            <Button
              decoration='basic'
              borderColor={colors.primaryColor}
              color='transparent'
              size='s'
              style={{
                borderLeft: 'none',
                borderRight: 'none',
                background: 'transparent',
              }}
            >
              <Loader />
            </Button>
          ) : isClaimable ? (
            <Button
              decoration='reverse'
              borderColor={colors.primaryColor}
              color={colors.primaryColor}
              size='s'
              onClick={(event) => handleClaimClick(event, task.id)}
              style={{
                letterSpacing: '3.6px',
                fontSize: '14px',
                color: colors.whiteColor,
              }}
            >
              CLAIM
            </Button>
          ) : isChecked ? (
            <Button
              onClick={handleCheckClick}
              decoration={isLoading ? 'basic' : 'reverse'}
              borderColor={colors.primaryColor}
              color={isLoading ? 'transparent' : colors.primaryColor}
              size='s'
              style={{
                letterSpacing: '3.6px',
                fontSize: '14px',
                color: colors.whiteColor,
                ...(isLoading && {
                  borderLeft: 'none',
                  borderRight: 'none',
                  background: 'transparent',
                }),
              }}
            >
              {isLoading ? <Loader /> : 'CHECK'}
            </Button>
          ) : (
            <Button
              decoration='basic'
              borderColor={colors.primaryColor}
              size='s'
            >
              <Gradient color={colors.primaryColor}>
                {formatNumber(+task.reward)}
                <StyledButtonIcon>
                  <LogoIcon width={20} height={20} />
                </StyledButtonIcon>
              </Gradient>
            </Button>
          )}
        </TaskButton>
      </TaskLink>
      {(task.type === 'tap' ||
        task.type === 'referrals' ||
        task.type === 'invite') && (
        <ProgressBar
          current={task.amount || refCount}
          target={task.target || task.validationParam}
        />
      )}
    </Task>
  );
};
