import { ROUTES } from 'app';
import { colors } from 'app/colors';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/button';

import { useModal } from 'shared/modal';
import { SuccessMint } from './SuccessMint';
import { useEffect, useState } from 'react';
import { confetti } from '@tsparticles/confetti';
import { useAppDispatch, useAppSelector } from 'app/store/rootStore';

import { Gradient } from 'shared/gradient';
import { selectBalance, selectMainQuests, updateBalance } from 'entities/user';
import { useMintMutation } from 'entities/user/model/api';

import { useToast } from 'shared/toast';
import { CompliteTasks } from './CompliteTasks';
import { Mint } from './Mint';
import { userSlice } from 'entities/user/model/slice';
import { Text } from 'shared/typography';

export const FakeMintButton = () => {
  const { setIsOpen, setModalProps } = useModal();
  const walletBalance = useAppSelector(selectBalance);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mainQuests = useAppSelector(selectMainQuests);
  const [mint, { isLoading, isSuccess, isError, data }] = useMintMutation();

  const isAllTasksComplite =
    mainQuests.length > 0 &&
    mainQuests.every((item) => item.completed === true);

  const closeHandle = () => {
    setIsOpen(false);
  };

  const onSuccess = async () => {
    dispatch(userSlice.actions.updateMintDate(data.mintDate));
    closeHandle();
    navigate(ROUTES.LAUNCH, { replace: true });
    void confetti({
      particleCount: 80,

      spread: 100,
      origin: { y: 1, x: 1 },
    });
    void confetti({
      particleCount: 80,

      spread: 100,
      origin: { y: 1, x: 0 },
    });
    dispatch(updateBalance(walletBalance - 3000)); // Обновляем баланс после ожидания
  };
  const onError = async () => {
    showToast('MINTING ERROR', 'error'); // Показываем сообщение об ошибке
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      onError();
    }
  }, [isError]);

  const goToTasks = () => {
    navigate(ROUTES.TASKS);
    setIsOpen(false);
  };

  const handleClick = () => {
    setIsOpen(true);
    if (walletBalance >= 3000) {
      setModalProps({
        onRequestClose: closeHandle,
        children: (
          <Mint
            onNext={() => {
              mint('');
            }}
          />
        ),
      });
    } else {
      setModalProps({
        onRequestClose: closeHandle,
        children: (
          <CompliteTasks
            onNext={() => {
              goToTasks();
            }}
          />
        ),
      });
    }
  };

  return (
    <>
      {/* Ожидаю модалку */}

      <Button
        isLoading={isLoading}
        onClick={handleClick}
        decoration='basic'
        borderColor={colors.primaryColor}
        size='l'
      >
        <Gradient color={colors.primaryColor}>
          <Text>MINT SINT</Text>
        </Gradient>
      </Button>
    </>
  );
};
