import * as React from 'react';
import { IconProps } from './icon.types';

export const Logo: React.FC<IconProps> = ({
  color = '#fff',
  width,
  height,
  size = 24,
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size || width}
    height={size || height}
    viewBox='0 0 25 24'
    fill='none'
    {...props}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M20.4516 12.7099C21.1419 13.5273 21.4971 14.4282 21.4971 15.3335C21.4971 16.7361 20.6444 18.1282 19.0139 19.2154C17.3903 20.2979 15.0897 21 12.4986 21C9.90745 21 7.60686 20.2979 5.98326 19.2154C4.35267 18.1282 3.5 16.7361 3.5 15.3335L2.5 15.3334C2.5 19.0152 6.97651 22 12.4986 22C18.0206 22 22.4971 19.0153 22.4971 15.3335C22.4971 11.6517 18.0206 8.66705 12.4986 8.66705C9.25683 8.66705 6.37542 9.69566 4.54848 11.2901C3.85814 10.4727 3.50292 9.57175 3.50292 8.66648C3.50292 7.26391 4.35559 5.87177 5.98618 4.78459C7.60978 3.70206 9.91037 3 12.5015 3C15.0926 3 17.3932 3.70206 19.0168 4.78459C20.6474 5.87177 21.5 7.26391 21.5 8.66648L22.5 8.66663C22.5 4.98483 18.0235 2 12.5015 2C6.97943 2 2.50292 4.98468 2.50292 8.66648C2.50292 12.3483 6.97943 15.333 12.5015 15.333C15.7432 15.333 18.6246 14.3043 20.4516 12.7099ZM19.743 12.0012C19.5205 11.8109 19.2773 11.6273 19.0139 11.4516C17.3903 10.3691 15.0897 9.66705 12.4986 9.66705C9.90745 9.66705 7.60686 10.3691 5.98326 11.4516C5.72095 11.6265 5.47878 11.8093 5.25705 11.9988C5.47957 12.1891 5.72272 12.3727 5.98618 12.5484C7.60978 13.6309 9.91037 14.333 12.5015 14.333C15.0926 14.333 17.3932 13.6309 19.0168 12.5484C19.2791 12.3735 19.5213 12.1907 19.743 12.0012Z'
      fill={color}
    />
  </svg>
);
