import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const reverseSpin = keyframes`
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 25;
`;

export const SpinnerGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.75rem;
  height: 18.75rem;
`;

export const SpinnerCircle = styled.div<{
  size: number;
  borderPx: number;
  borderTopPx: number;
  color: string;
  duration: string;
  direction: 'normal' | 'reverse';
}>`
  position: absolute;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: ${({ borderPx }) => borderPx}px solid rgba(0, 0, 0, 0);
  border-top: ${({ borderTopPx, color }) => `${borderTopPx}px solid ${color}`};
  border-radius: 50%;
  animation: ${({ direction }) =>
      direction === 'reverse' ? reverseSpin : spin}
    ${({ duration }) => duration} linear infinite;
`;
