import { colors } from 'app/colors';
import { IconProps } from '../icon.types';

export const ToastIcon: React.FC<IconProps> = ({
  color = colors.whiteColor,
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 2.75C12.429 2.75 12.8474 2.83323 13.2437 2.99739L17.4864 4.75476C18.2884 5.08697 18.9131 5.71163 19.2453 6.51365L21.0026 10.7563C21.3348 11.5583 21.3348 12.4417 21.0026 13.2437L19.2453 17.4864C18.9131 18.2884 18.2884 18.9131 17.4864 19.2453L13.2437 21.0026C12.8474 21.1668 12.429 21.25 12 21.25C11.5711 21.25 11.1526 21.1668 10.7563 21.0026L6.51365 19.2453C5.71161 18.9131 5.08696 18.2884 4.75476 17.4864L2.9974 13.2437C2.66518 12.4417 2.66518 11.5583 2.9974 10.7563L4.75476 6.51365C5.08696 5.71161 5.71163 5.08697 6.51365 4.75476L10.7563 2.99739C11.1526 2.83323 11.5711 2.75 12 2.75ZM12 2C11.4797 2 10.9593 2.1015 10.4693 2.30449L6.22663 4.06185C5.24651 4.46782 4.46783 5.24652 4.06185 6.22663L2.30449 10.4693C1.8985 11.4494 1.8985 12.5506 2.30449 13.5308L4.06185 17.7734C4.46783 18.7535 5.24652 19.5322 6.22663 19.9382L10.4693 21.6955C10.9593 21.8985 11.4797 22 12 22C12.5204 22 13.0407 21.8985 13.5308 21.6955L17.7734 19.9382C18.7535 19.5322 19.5322 18.7535 19.9382 17.7734L21.6955 13.5307C22.1015 12.5506 22.1015 11.4494 21.6955 10.4693L19.9382 6.22663C19.5322 5.24652 18.7535 4.46782 17.7734 4.06185L13.5307 2.30449C13.0407 2.1015 12.5204 2 12 2Z'
      fill={color}
    />
  </svg>
);
