import { H100, Header, PageContent, PageWrapper } from 'shared/layout';

import { PageTitle } from 'shared/typography/Typography.styled';
import { LeaderBoardTabs } from 'widget/LeaderBoardTabs';
import { Menu } from 'widget/menu';

export const LeaderBoard = () => {
  return (
    <PageWrapper>
      <Header>
        <div />
        <PageTitle>Rating</PageTitle>
        <div />
      </Header>
      <PageContent>
        <LeaderBoardTabs />
      </PageContent>
      <Menu />
    </PageWrapper>
  );
};
