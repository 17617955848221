import styled from 'styled-components';

export const CarouselContainer = styled.div`
  position: relative;
  min-height: 44px;
`;

export const GradientLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), transparent);
  pointer-events: none;
  z-index: 2;
`;

export const GradientRight = styled.div`
  top: 0;
  right: 0;
  width: 10%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.7), transparent);
  pointer-events: none;
  z-index: 2;
`;
