import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import {
  BUTTON_SIZE,
  generateData,
  GeneratedElement,
  SCENE_SIZE,
  Sequence,
  STAGES,
  TIME_DECREMENT,
  TOTAL_SEQUENCES,
  transformSequencesToSteps,
} from '../utils/generator';
import { Button, ButtonSize } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { Carousel } from 'shared/carousel';
import { CentredWrapper, H100 } from 'shared/layout';
import { colors } from 'app/colors';
import { GradientTimer } from 'shared/gradientTimer';
import { Step, StepDirection, Steps } from 'shared/steps';
import {
  BubbleArea,
  ExitWrapper,
  GameButton,
  GameWrapper,
  InfoWrapper,
  RobotWrapper,
  Scene3dContainerGamePage,
  StatsWrapper,
  Streak,
} from '../Game.styled';
import { Energy } from 'feature/game/energy';
import { useAppDispatch, useAppSelector } from 'app/store/rootStore';
import {
  selectBalance,
  selectEnergy,
  selectTapValue,
  updateBalance,
  updateEnergy,
} from 'entities/user';
import { Text } from 'shared/typography';
import { use3dModelContext } from '../../3d';
import { StyledText } from 'widget/fakeMint/FakeMint.styled';
import { Counter } from 'shared/counter';
import { setIsShowMenu, setShowStars } from 'entities/settings';
import { CloseIcon } from 'shared/icon';
import { useModal } from 'shared/modal';
import { OutOfEnergy } from './OutOfEnergy';
import { CharacterStats } from 'widget/characterStats';
import {
  useStartSessionMutation,
  useUpdateBalanceMutation,
} from 'entities/user/model/api';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app';
import { useToast } from 'shared/toast';

const MemoizedGameButton = React.memo(GameButton);
const MemoizedGradientTimer = React.memo(GradientTimer);
const MemoizedSteps = React.memo(Steps);

const defaultSeqences = {
  primary: [],
  fake: [],
  final: [],
};

export const MainGame: React.FC = () => {
  const energy = useAppSelector(selectEnergy);
  const walletBalance = useAppSelector(selectBalance);
  const tapValue = useAppSelector(selectTapValue);
  const dispatch = useAppDispatch();
  const { setIsOpen, setModalProps } = useModal();
  const { mount3dScene, unmount3dScene, movementControllerOfModel } =
    use3dModelContext();
  const [syncParams] = useUpdateBalanceMutation();
  const [startSession, { isLoading, isError }] = useStartSessionMutation();
  const [gameStarted, setGameStarted] = useState(false);
  const [clicked, setClicked] = useState<any>([]);
  const [stage, setStage] = useState<number>(1);

  const [correctSequencesCount, setCorrectSequencesCount] = useState(0);
  const [incorrectSequencesCount, setincorrectSequencesCount] = useState(0);

  const { showToast } = useToast();

  const [correctSequencesBtwnSync, setCorrectSequencesBtwnSync] = useState(0);
  const [incorrectSequencesBtwnSync, setIncorrectSequencesBtwnSync] =
    useState(0);

  const [sequences, setSequences] = useState<Sequence>(defaultSeqences);
  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const [sequenceCompleted, setSequenceCompleted] = useState(false);
  const [sequenceFailed, setSequenceFailed] = useState(false);

  const [streak, setStreak] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [stepMulti, setStepMulti] = useState<any>([]);

  const [timer, setTimer] = useState(STAGES[1].gameTime);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const scene3dContainerGamePage = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const carouselRef = useRef<any>(null);

  const [randomPositions, setRandomPositions] = useState<
    { top: number; left: number; size: ButtonSize }[]
  >([]);

  const apiSync = () => {
    console.log({
      amount: walletBalance,
      energy: energy,
      timestamp: new Date().getTime(),
      correctSequences: correctSequencesCount,
      incorrectSequences: incorrectSequencesCount,
      streaks: stepMulti,
      stage: stage,
    });
    syncParams({
      amount: walletBalance,
      energy: energy,
      timestamp: new Date().getTime(),
      correctSequences: correctSequencesBtwnSync,
      incorrectSequences: incorrectSequencesBtwnSync,
      streaks: stepMulti,
      stage: stage,
    });
    setIncorrectSequencesBtwnSync(0);
    setStepMulti([]);
    setCorrectSequencesBtwnSync(0);
  };

  const handleOutOfEnergy = () => {
    setIsOpen(false);
    if (gameStarted) {
      closeGame();
    }
  };
  const generate = useCallback(() => {
    if (energy - 1 < 3) {
      setModalProps({
        onRequestClose: handleOutOfEnergy,
        children: <OutOfEnergy stage={stage} onNext={handleOutOfEnergy} />,
      });
      setIsOpen(true);
      return;
    }

    const generatedSequences: Sequence = sequences;
    console.log(correctSequencesCount);
    for (let i = 0; i < TOTAL_SEQUENCES; i++) {
      const correctStage = stage + 1 < 11 ? stage + 1 : stage;
      const stageCalc = currentSequenceIndex % 10 === 9 ? correctStage : stage;
      const { primaryArray, finalArray, fakeElements } = generateData(
        STAGES[stageCalc].minPrimary,
        STAGES[stageCalc].maxPrimary,
        STAGES[stageCalc].minFake,
        STAGES[stageCalc].maxFake
      );

      generatedSequences.final.push(finalArray);
      generatedSequences.primary.push(primaryArray);
      generatedSequences.fake.push(fakeElements);
    }
    setTimer(STAGES[stage].gameTime);

    setSequences(generatedSequences);
    setGameStarted(true);
  }, [
    sequences.final,
    currentSequenceIndex,
    correctSequencesCount,
    movementControllerOfModel,
    stage,
    energy,
    defaultSeqences,
    gameStarted,
  ]);

  useEffect(() => {
    if (streak > 0 && streak % 10 === 0 && streak / 10 < 10) {
      setMultiplier(streak + 1 < 11 ? streak + 1 : multiplier);
    } else if (streak === 0) {
      setMultiplier(1);
    }
  }, [streak]);

  useEffect(() => {
    if (isError) {
      showToast('GAME START FAILED!', 'error');
    }
  }, [isError]);

  const handleClick = useCallback(
    async (step: number) => {
      try {
        const currentSequence = sequences.primary[currentSequenceIndex];
        setClicked((prev: boolean[]) => [...prev, true]);
        dispatch(updateEnergy(energy - 1));
        if (step === currentSequence[currentStep].step) {
          const isLastStep = currentStep + 1 === currentSequence.length;
          if (isLastStep) {
            completeSequence();
          } else {
            setCurrentStep((prevStep) => prevStep + 1);
          }
        } else {
          failSequence();
        }
      } catch (error) {
        failSequence();
      }
    },
    [
      sequenceCompleted,
      sequenceFailed,
      currentSequenceIndex,
      currentStep,
      energy,
      dispatch,
    ]
  );

  useLayoutEffect(() => {
    if (gameStarted && sequences.final[currentSequenceIndex]) {
      generateRandomPositions(sequences.final[currentSequenceIndex]);
    }
  }, [currentSequenceIndex, gameStarted, sequences.primary]);

  useEffect(() => {
    if (
      correctSequencesCount > 0 &&
      correctSequencesCount % 10 === 0 &&
      correctSequencesCount < 100
    ) {
      setStage(correctSequencesCount / 10 + 1);
      dispatch(setShowStars(true));
    }
    if (correctSequencesCount % 2 === 0 && correctSequencesCount > 0) {
      apiSync();
    }
  }, [correctSequencesCount]);

  const completeSequence = useCallback(() => {
    dispatch(setShowStars(false));
    setSequenceCompleted(true);

    setTimeout(() => {
      const nextSequenceIndex = currentSequenceIndex + 1;

      if (nextSequenceIndex === sequences.primary.length) {
        setCorrectSequencesCount((prev) => prev + 1);
        setCorrectSequencesBtwnSync((prev) => prev + 1);

        generate();
        setCurrentSequenceIndex(nextSequenceIndex);
        setCurrentStep(0);
        setSequenceCompleted(false);

        setStreak((prevStreak) => prevStreak + 1);
        setMultiplier((prevStreak) =>
          prevStreak + 1 < 11 ? prevStreak + 1 : prevStreak
        );

        if (stepMulti.length < 10) {
          setStepMulti([...stepMulti, multiplier]);
        } else {
          setStepMulti([multiplier]);
        }
        dispatch(
          updateBalance(
            walletBalance + STAGES[stage].reward * tapValue * multiplier
          )
        );

        setClicked([]);

        carouselRef.current.goToSlide(nextSequenceIndex);
        movementControllerOfModel?.changeSintPosByRandom();
      }
    });
  }, [
    currentSequenceIndex,
    currentStep,
    walletBalance,
    stepMulti,
    sequences.primary,
    dispatch,
    generate,
    multiplier,
    streak,
  ]);

  const failSequence = useCallback(() => {
    setSequenceFailed(true);
    clearInterval(timerRef.current!);

    setTimeout(() => {
      const nextSequenceIndex = currentSequenceIndex + 1;
      if (nextSequenceIndex === sequences.primary.length) {
        generate();

        setCurrentSequenceIndex(nextSequenceIndex);
        setCurrentStep(0);
        setSequenceFailed(false);
        setincorrectSequencesCount((prev) => prev + 1);
        setIncorrectSequencesBtwnSync((prev) => prev + 1);
        setStreak(0);
        setMultiplier(1);
        setClicked([]);
        carouselRef.current.goToSlide(nextSequenceIndex);
        resetTimer(STAGES[stage].gameTime);
      }
    });
  }, [currentSequenceIndex, sequences.primary, generate]);

  useEffect(() => {
    if (timer < TIME_DECREMENT) {
      failSequence();
    }
  }, [timer]);

  useEffect(() => {
    if (gameStarted) {
      movementControllerOfModel?.moveSintToStartGame();
      dispatch(setIsShowMenu(false));
      resetTimer(STAGES[stage].gameTime);
    }

    return () => {
      dispatch(setIsShowMenu(true));
      clearInterval(timerRef.current!);
    };
  }, [gameStarted]);

  const resetTimer = (time: number) => {
    clearInterval(timerRef.current!);
    setTimer(time);

    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        return prev - 1000;
      });
    }, 1000);
  };

  const closeGame = () => {
    console.log('GAME END');
    // TODO Vitalii - I added this request because when game has been closed, balance and energy didn-t saved.
    apiSync();
    movementControllerOfModel?.moveSintToGamePageWithAnim();
    setSequences(() => ({ fake: [], primary: [], final: [] }));
    setClicked([]);
    setCurrentSequenceIndex(0);
    setGameStarted(false);
    setStepMulti([]);
    setStreak(0);
    setMultiplier(1);
    setStage(1);
    setCorrectSequencesCount(0);
    setTimer(STAGES[1].gameTime);
  };

  useEffect(() => {
    console.log('Updated sequences:', sequences);
  }, [sequences.primary]);

  const generateRandomPositions = useCallback(
    (elements: GeneratedElement[]) => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;

      const sizes = STAGES[stage].bubbleSize;
      const positions: { top: number; left: number; size: ButtonSize }[] = [];

      while (positions.length < elements.length) {
        const top = Math.random() * (containerHeight - BUTTON_SIZE);
        const left = Math.random() * (containerWidth - BUTTON_SIZE);
        const isOverlapping = positions.some(
          (pos) =>
            Math.abs(pos.top - top) < BUTTON_SIZE &&
            Math.abs(pos.left - left) < BUTTON_SIZE
        );

        if (!isOverlapping) {
          const size: any = sizes[Math.floor(Math.random() * sizes.length)];
          positions.push({ top, left, size });
        }
      }
      setRandomPositions(positions);
    },
    [currentSequenceIndex]
  );
  const currentBubble = sequences.final[currentSequenceIndex] || [];

  useEffect(() => {
    if (scene3dContainerGamePage && scene3dContainerGamePage.current) {
      console.log('SCENE-MOUNT', scene3dContainerGamePage.current.id);
      mount3dScene(scene3dContainerGamePage.current.id);
    }
  }, [scene3dContainerGamePage]);

  useEffect(() => {
    if (
      movementControllerOfModel &&
      scene3dContainerGamePage &&
      scene3dContainerGamePage.current
    ) {
      movementControllerOfModel.moveSintToGamePage();
    }
  }, [movementControllerOfModel]);

  useLayoutEffect(() => {
    return () => {
      console.log('SCENE-UNMOUNT');
      unmount3dScene();
    };
  }, []);

  return (
    <>
      {!gameStarted && <CharacterStats />}

      <GameWrapper id={'GAME-WRAPPER'}>
        <RobotWrapper>
          <Scene3dContainerGamePage
            id={'3d-scene-game-page'}
            ref={scene3dContainerGamePage}
          />
        </RobotWrapper>
        {!gameStarted ? (
          <CentredWrapper>
            <div
              style={{ width: SCENE_SIZE.width, height: SCENE_SIZE.height }}
            ></div>

            <StyledText>
              <>
                Train Your SYNTHETIC INTELLIGENCE,
                <br />
                Own the Future
              </>
            </StyledText>
            <Button
              onClick={() => {
                startSession('')
                  .unwrap()
                  .then(() => generate());
              }}
              decoration='basic'
              size='l'
              isLoading={isLoading}
              style={{ zIndex: 10 }}
              borderColor={colors.secondaryColor}
            >
              <Gradient color={colors.secondaryColor}>
                <Text style={{ fontSize: 20 }}>Play</Text>
              </Gradient>
            </Button>
          </CentredWrapper>
        ) : (
          <>
            <Carousel
              ref={carouselRef}
              arrows={false}
              draggable={false}
              swipeable={false}
            >
              {sequences.primary.map((sequence, seqIndex) => (
                <MemoizedSteps
                  key={`seq-${seqIndex}`}
                  isFailed={sequenceFailed}
                  steps={transformSequencesToSteps(sequence)}
                  isActive={seqIndex === currentSequenceIndex}
                  currentStep={currentStep}
                  direction={StepDirection.Horizontal}
                  gameTime={STAGES[stage].gameTime}
                />
              ))}
            </Carousel>
            <ExitWrapper>
              <Button
                onClick={() => {
                  closeGame();
                }}
                borderSize={0}
                size='xs'
                borderColor={'#FFFFFF40'}
              >
                <CloseIcon />
              </Button>
            </ExitWrapper>
            <BubbleArea ref={containerRef}>
              {currentBubble.map((element, index) => {
                const { top, left, size } = randomPositions[index] || {
                  top: -1000,
                  left: -2000,
                  size: 'm',
                };

                return (
                  <MemoizedGameButton
                    isAnimating={clicked[index]}
                    key={`${index}-${element.step}-${element.value}-${element.color}`}
                    style={{
                      position: 'absolute',
                      top: `${top}px`,
                      left: `${left}px`,
                    }}
                    shape='round'
                    size={size}
                    onClick={() => {
                      handleClick(element.step);
                    }}
                  >
                    <MemoizedGradientTimer
                      color={element.color}
                      isAnim={
                        STAGES[stage].help
                          ? currentStep === element.step
                          : false
                      }
                      borderWidth={0}
                      content={
                        <div style={{ fontSize: 20, fontWeight: 600 }}>
                          {element.value}
                        </div>
                      }
                      isBlum={false}
                      static
                      status='active'
                    />
                  </MemoizedGameButton>
                );
              })}
            </BubbleArea>
            <StatsWrapper>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 8,
                }}
              >
                time
                <Text style={{ fontSize: 14 }}>
                  {STAGES[stage].gameTime / 1000}
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 8,
                }}
              >
                STAGE
                <Text style={{ fontSize: 14 }}>
                  <Counter value={stage + '/10'} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                factor
                <Text style={{ fontSize: 14 }}>
                  <Counter value={'x' + multiplier} />
                </Text>
              </Text>
              {/* <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                Correct Sequences
                <Text style={{ fontSize: 14 }}>
                  <Counter value={correctSequencesCount} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                step multi
                <Text style={{ fontSize: 14 }}>
                  <Counter value={stepMulti} />
                </Text>
              </Text>
              <Text
                style={{
                  color: '#FFFFFF80',
                  fontSize: 10,
                  lineHeight: '16px',
                  marginBottom: 0,
                }}
              >
                currentSequenceIndex
                <Text style={{ fontSize: 14 }}>
                  <Counter value={currentSequenceIndex} />
                </Text>
              </Text> */}
            </StatsWrapper>
            <InfoWrapper>
              {/* <span
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                {streak > 0 && (
                  <StreakAnim value={<Streak>{`${streak}`}</Streak>} />
                )}
              </span> */}
              <Energy />
            </InfoWrapper>
          </>
        )}
      </GameWrapper>
    </>
  );
};
