import { StyledText } from './FakeMint.styled';
import { FakeMintButton } from 'feature/fakeMint';
import { use3dModelContext } from '../../feature/3d';
import { CentredWrapper, Scene3dContainer } from 'shared/layout';
import React, { useEffect, useLayoutEffect, useRef } from 'react';

const SCENE_SIZE = {
  width: 330,
  height: 350,
};

export const FakeMint = () => {
  const { mount3dScene, unmount3dScene, movementControllerOfModel } =
    use3dModelContext();
  const sceneContainerMintPage = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (sceneContainerMintPage && sceneContainerMintPage.current) {
      console.log('SCENE-MOUNT', sceneContainerMintPage.current.id);
      mount3dScene(sceneContainerMintPage.current.id);
    }
  }, [sceneContainerMintPage]);

  useEffect(() => {
    if (
      movementControllerOfModel &&
      sceneContainerMintPage &&
      sceneContainerMintPage.current
    ) {
      movementControllerOfModel.moveHologramToMintPage();
    }
  }, [movementControllerOfModel]);

  useLayoutEffect(() => {
    return () => {
      console.log('SCENE-UNMOUNT');
      unmount3dScene();
    };
  }, []);

  return (
    <CentredWrapper>
      <Scene3dContainer
        id={'3d-scene-mint-page'}
        ref={sceneContainerMintPage}
        style={{
          width: '100%',
          height: SCENE_SIZE.height + 'px',
        }}
      />

      <StyledText>
        Create Your SYNTHETIC
        <br /> INTELLIGENCE
      </StyledText>
      <FakeMintButton />
    </CentredWrapper>
  );
};
