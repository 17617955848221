import styled, { css } from 'styled-components';
import { ButtonProps } from './button.types';
import { BorderIcon } from 'shared/icon';

const sizeStyles = {
  l: css`
    width: 160px;
    height: 56px;
  `,
  m: css`
    width: 132px;
    height: 42px;
  `,
  s: css`
    width: 100px;
    height: 42px;
  `,
  xs: css`
    width: 27px;
    height: 27px;
  `,
};

const roundSizeStyles = {
  s: css`
    width: 27px;
    height: 27px;
  `,
  m: css`
    width: 50px;
    height: 50px;
  `,
  l: css`
    width: 68px;
    height: 68px;
  `,
};
const borderWidth = 1;

export const StyledButton = styled.button<ButtonProps>`
  ${({ size, shape, borderColor, decoration, color, borderSize }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    overflow: visible;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    border-left: none !important;
    border-right: none !important;
    background-color: ${color ? color : 'transparent'};
    border: ${borderColor ? `${borderSize}px solid ${borderColor}` : null};
    ${decoration === 'reverse' &&
    css`
      border-right-color: transparent;
      border-left-color: transparent;
    `}

    ${shape === 'round'
      ? css`
          ${roundSizeStyles[size as keyof typeof roundSizeStyles] ||
          roundSizeStyles.s}
          border-radius: 50%;
        `
      : sizeStyles[size as keyof typeof sizeStyles]};
  `}

  &:active {
    transform: scale(0.98);
  }
`;

export const StyledBorderIcon = styled(BorderIcon)<{
  isFirst?: boolean;
  decoration?: 'basic' | 'reverse';
}>`
  height: calc(100% + ${borderWidth * 2}px);
  position: relative;
  display: ${({ decoration }) => (decoration ? 'block' : 'none')};
  top: 0.2px;
  ${({ isFirst, decoration }) =>
    isFirst
      ? css`
          left: -3px;
          transform: ${decoration === 'reverse' ? 'none' : 'rotate(180deg)'};
        `
      : css`
          right: -3px;
          transform: ${decoration === 'reverse' ? 'rotate(180deg)' : 'none'};
        `}
`;

export const Content = styled.div`
  border-radius: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96%;
`;
