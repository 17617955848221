import { colors } from 'app/colors';

import {
  BlockContent,
  BlockWrapper,
  BonusContainer,
  ButtonWrapper,
  CentredWrapper,
  CopyContainer,
  InputWrapper,
  TextContainer,
} from 'shared/layout';
import { Text } from 'shared/typography';
import { Input } from 'shared/input';
import { Copy } from 'shared/copy';

import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import { WalletIcon } from 'shared/icon';
import { useTonAddress } from '@tonconnect/ui-react';
import { ConnectWallet } from 'feature/web3';
import { formatAdress } from 'shared/utils';

export const ConnectWalletContent = () => {
  const friendlyAdress = useTonAddress();

  const isConnected = friendlyAdress;
  const color = friendlyAdress ? colors.secondaryColor : colors.whiteColor;

  return (
    <CentredWrapper
      style={{
        width: '100%',
        color: 'red !important',
      }}
    >
      <BlockWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <DivDecoration height={'100px'} width={11} opacity={0.25} />
          <BonusContainer background={isConnected && '#D8F34F1A'}>
            <Text
              style={{
                fontWeight: 600,
                fontSize: '80px',
                lineHeight: '0.6',
              }}
            >
              <WalletIcon color={color} size={80} />
            </Text>
          </BonusContainer>
          <DivDecoration isReverse height={'100px'} width={11} opacity={0.25} />
        </div>
        <BlockContent
          style={{
            marginTop: '12px',
          }}
        >
          <Text style={{}}></Text>
          <TextContainer
            style={{
              marginTop: '5px',
            }}
          ></TextContainer>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        <BlockContent style={{ marginTop: '60px' }}>
          <Text
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              letterSpacing: '4px',
              fontWeight: 600,
              color,
              gap: '10px',
            }}
          >
            {isConnected ? (
              <>Your wallet is connected</>
            ) : (
              'Connect your wallet'
            )}
          </Text>
          <TextContainer>
            <Text
              style={{
                fontSize: '12px',
                marginTop: '8px',
                color,
                width: 252,
              }}
            >
              {isConnected
                ? 'Now you can participate in further in-app activities'
                : 'To participate in further inn-app activities'}
            </Text>
          </TextContainer>
        </BlockContent>
        <BlockContent
          style={{
            marginTop: '18px',
          }}
        >
          <TextContainer>
            <Text
              style={{
                fontSize: '12px',
                marginTop: '2px',
              }}
            ></Text>
          </TextContainer>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        <InputWrapper>
          {isConnected && (
            <>
              {' '}
              <Input
                disabled
                bgColor={colors.inputBgColor}
                color={colors.whiteColor}
                placeholderColor={colors.whiteColor}
                type='text'
                value={formatAdress(friendlyAdress)}
                onChange={() => {}}
                placeholder={'wallet adress'}
              />
              <CopyContainer>
                <Copy
                  textToCopy={friendlyAdress || ''}
                  alertText='Wallet address copied'
                />
              </CopyContainer>
            </>
          )}
        </InputWrapper>
        <ButtonWrapper>
          <ConnectWallet />
        </ButtonWrapper>
      </BlockWrapper>
    </CentredWrapper>
  );
};
