import styled from 'styled-components';

export const QRWrapper = styled.div`
  background: transparent;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 200px;
    max-height: 200px;
  }
`;
