import { colors } from 'app/colors';
import { LogoText, Wrapper } from './Logo.styled';
import { LogoProps } from './logo.types';

export const Logo: React.FC<LogoProps> = ({
  fontSize = '24px',
  width = 51,
  height = 47,
  color = colors.whiteColor,
  gradient = false,
  isLoading = false,
}) => (
  <Wrapper>
    {!gradient ? (
      !isLoading ? (
        <LogoText fontSize={fontSize} color={color}>
          SINT
        </LogoText>
      ) : (
        <svg
          width='116'
          height='18'
          viewBox='0 0 116 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M5.27666 0.4H18.1027V1.874H5.27666C4.36733 1.874 3.58999 2.19667 2.94466 2.842C2.29932 3.48733 1.97666 4.26467 1.97666 5.174C1.97666 6.08333 2.29932 6.86067 2.94466 7.506C3.58999 8.13667 4.36733 8.452 5.27666 8.452H13.3287C14.6487 8.452 15.7707 8.92133 16.6947 9.86C17.6333 10.784 18.1027 11.906 18.1027 13.226C18.1027 14.546 17.6333 15.6753 16.6947 16.614C15.7707 17.538 14.6487 18 13.3287 18H0.502658V16.526H13.3287C14.238 16.526 15.0153 16.2033 15.6607 15.558C16.306 14.9127 16.6287 14.1353 16.6287 13.226C16.6287 12.3167 16.306 11.5467 15.6607 10.916C15.0153 10.2707 14.238 9.948 13.3287 9.948H5.27666C3.95666 9.948 2.82732 9.486 1.88866 8.562C0.964658 7.62333 0.502658 6.494 0.502658 5.174C0.502658 3.854 0.964658 2.732 1.88866 1.808C2.82732 0.869333 3.95666 0.4 5.27666 0.4ZM38.7827 18V0.4H40.2567V18H38.7827ZM63.5019 2.974C65.2179 1.258 67.2932 0.4 69.7279 0.4C72.1626 0.4 74.2379 1.258 75.9539 2.974C77.6699 4.69 78.5279 6.76533 78.5279 9.2V18H77.0539V9.2C77.0539 7.88 76.7239 6.65533 76.0639 5.526C75.4186 4.39667 74.5312 3.50933 73.4019 2.864C72.2726 2.204 71.0479 1.874 69.7279 1.874C68.4079 1.874 67.1832 2.204 66.0539 2.864C64.9246 3.50933 64.0299 4.39667 63.3699 5.526C62.7246 6.65533 62.4019 7.88 62.4019 9.2V18H60.9279V9.2C60.9279 6.76533 61.7859 4.69 63.5019 2.974ZM97.8974 1.874V0.4H115.497V1.874H107.445V18H105.949V1.874H97.8974Z'
            fill='white'
          />
        </svg>
      )
    ) : (
      <svg
        width={width}
        height={height}
        viewBox='0 0 70 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M61.5323 35.2458C63.8857 37.6917 64.9585 40.2707 64.9585 42.6677C64.9585 46.25 62.5622 50.2387 57.0767 53.5824C51.6626 56.8825 43.8695 59.0843 34.9973 59.0843C26.1252 59.0843 18.3321 56.8825 12.918 53.5824C7.43244 50.2387 5.03614 46.25 5.03614 42.6677V42.6668H0V42.6677C0 54.4492 15.6688 64 34.9973 64C54.3258 64 69.9947 54.4492 69.9947 42.6677C69.9947 30.8862 54.3258 21.3354 34.9973 21.3354C24.3905 21.3354 14.8858 24.2116 8.46774 28.7542C6.11435 26.3083 5.04146 23.7293 5.04146 21.3323C5.04146 17.75 7.43776 13.7613 12.9233 10.4176C18.3374 7.11749 26.1305 4.9157 35.0027 4.9157C43.8748 4.9157 51.6679 7.11749 57.082 10.4176C62.5676 13.7613 64.9639 17.75 64.9639 21.3323V21.3332H70V21.3323C70 9.55079 54.3312 0 35.0027 0C15.6742 0 0.00531634 9.55079 0.00531634 21.3323C0.00531634 33.1138 15.6742 42.6646 35.0027 42.6646C45.6095 42.6646 55.1142 39.7884 61.5323 35.2458ZM57.4771 32.0017C57.3455 31.9184 57.212 31.8355 57.0767 31.753C51.6626 28.4529 43.8695 26.2511 34.9973 26.2511C26.1252 26.2511 18.3321 28.4529 12.918 31.753C12.7845 31.8344 12.6528 31.9162 12.5229 31.9983C12.6545 32.0816 12.788 32.1645 12.9233 32.247C18.3374 35.5471 26.1305 37.7489 35.0027 37.7489C43.8748 37.7489 51.6679 35.5471 57.082 32.247C57.2155 32.1656 57.3472 32.0838 57.4771 32.0017Z'
          fill='url(#paint0_linear_7047_7586)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_7047_7586'
            x1='-0.428025'
            y1='31.2129'
            x2='70.4363'
            y2='32.1846'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#C7ACFF' />
            <stop offset='0.8706' stop-color='white' />
          </linearGradient>
        </defs>
      </svg>
    )}
  </Wrapper>
);
