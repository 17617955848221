import { colors } from 'app/colors';
import { ROUTES } from 'app/router';
// import { Level } from 'feature/level';
import { Button } from 'shared/button';
import { Text } from 'shared/typography';
import { NavLink } from 'react-router-dom';
import { use3dModelContext } from 'feature/3d';
import { UpgradeProgress } from 'widget/upgradeProgress';
import { ReferralsBalance, Username, UserId } from 'entities/user';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { CentredWrapper, Scene3dContainer } from 'shared/layout';
import {
  ButtonWrapper,
  Stat,
  StatsWrapper,
  StatWrapper,
} from './Profile.styled';
import profileImage from 'assets/sint-profile.webp';

export const ProfileContent = () => {
  const { mount3dScene, unmount3dScene } = use3dModelContext();
  const scene3dContainerProfilePage = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scene3dContainerProfilePage && scene3dContainerProfilePage.current) {
      console.log('SCENE-MOUNT', scene3dContainerProfilePage.current.id);
      mount3dScene(scene3dContainerProfilePage.current.id);
    }
  }, [scene3dContainerProfilePage]);

  useLayoutEffect(() => {
    return () => {
      console.log('SCENE-UNMOUNT');
      unmount3dScene();
    };
  }, []);

  return (
    <div>
      <CentredWrapper>
        {/* <Scene3dContainer
          id={'3d-scene-profile-page'}
          ref={scene3dContainerProfilePage}
          style={{
            width: '150px',
            height: '150px',
          }}
        /> */}
        <img
          src={profileImage}
          alt='Profile'
          style={{
            marginTop: 22,
            width: '156px',
            height: '192px',
            maskImage: 'linear-gradient(to top, transparent 0%, black 100%)',
            WebkitMaskImage:
              'linear-gradient(to top, transparent 0%, black 100%)',
          }}
        />
        <CentredWrapper>
          <Text
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 6,
              lineHeight: 2,
            }}
          >
            SINT_
            <UserId />
          </Text>
          <Text
            style={{
              fontWeight: 400,
              fontSize: 18,
              lineHeight: 2,
            }}
          >
            <Username />
          </Text>
          {/* <Level progress={false} /> */}
        </CentredWrapper>
        <StatsWrapper>
          <StatWrapper>
            EXP <Stat>-</Stat>
          </StatWrapper>
          <StatWrapper>
            Referrals{' '}
            <Stat>
              <ReferralsBalance />
            </Stat>
          </StatWrapper>
          <StatWrapper>
            Activity <Stat>-</Stat>
          </StatWrapper>
        </StatsWrapper>
        <ButtonWrapper>
          <NavLink to={ROUTES.REFERRAL}>
            <Button
              color={colors.notIntensiveColor}
              borderColor={colors.notIntensiveColor}
            >
              <Text>Referral</Text>
            </Button>
          </NavLink>
        </ButtonWrapper>
        <UpgradeProgress />
      </CentredWrapper>
    </div>
  );
};
